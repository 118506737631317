
import { Component, Vue } from "vue-property-decorator";
import DimssaButton from "@/components/shared/dimssa-button.vue";

@Component({
  components: {
    DimssaButton,
  },
})
export default class Feed extends Vue {
  async graphQlJson(gql: string) {
    return await this.$store.dispatch(
      "graphQl",
      {
        gql: gql,
        variables: { guid: this.$store.state.user.location.guid },
      },
      { root: true }
    );
  }
}
