
import { Component, Vue, Watch } from "vue-property-decorator";
import DimssaButton from "@/components/shared/dimssa-button.vue";
import lodash from "lodash";
import * as Models from "@gigalot/data-models";
import { mapFields } from "@/store";
import { changeTrackingComponentFragment } from "@/helpers/gql";

@Component({
  components: {
    DimssaButton,
  },
  computed: {
    ...mapFields(["transporter.name", "transporter.active"]),
  },
})
export default class Transporter extends Vue {
  drivers: Models.Driver[] = [];
  //intakeBatches: Models.IntakeBatch[] = [];

  changed: boolean = false;

  @Watch("changed")
  onChangeChanged(val: any) {
    this.$store.commit("navFuncs", { save: this.save, back: this.back });
  }

  async created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$store.commit("updateUserLocationField", { path: "transporter", value: {} });
        this.$router.push({ path: "list/transporters" });
      },
    });
    let transporter: Models.Transporter = this.$store.getters["storage"]().transporter;
    this.drivers = transporter.drivers;
    //this.intakeBatches = (await this.$store.dispatch("data/get", { objectStore: "IntakeBatch" })) as Models.IntakeBatch[];
  }

  @Watch("drivers")
  onDriversChanged(drivers: any) {
    console.log("drivers: " + JSON.stringify(drivers));
    this.$store.commit("updateUserLocationField", {
      path: "transporter.drivers",
      value: drivers,
    });
  }

  addDriver() {
    this.changed = true;
    this.drivers.push(new Models.Driver());
  }

  deleteDriver(index: number) {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure?",
      yesAction: async () => {
        this.changed = true;
        let transporter: Models.Transporter = this.$store.getters["storage"]().transporter;
        const driver = transporter.drivers[index];

        //check first that driver is not currently being used
        //if driver is being used then prevent deletion
        // let intakeBatches = (await this.$store.dispatch("data/get", { objectStore: "IntakeBatch" })) as Models.IntakeBatch[];
        // for (const intakeBatch of intakeBatches) {
        //   for (const transport of intakeBatch.transport) {
        //     if (transport.transporter && transport.transporter.drivers?.length && transport.transporter.drivers.find((d) => d.guid === driver.guid)) {
        //       this.$store.commit("popup/displayOk", `Cannot delete driver, it is being used in intake batch reference ${intakeBatch.reference}`);
        //       return;
        //     }
        //   }
        // }

        //TODO: look in dispatch to abattoir batches as well

        transporter.drivers.splice(index, 1);

        this.$store.commit("updateUserLocationField", {
          path: "transporter.drivers",
          value: transporter.drivers,
        });
      },
    });
  }

  back() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure? Unsaved work will be lost.",
      yesAction: () => {
        this.$store.commit("updateUserLocationField", { path: "transporter", value: {} });
        this.$router.push({ path: "list/transporters" });
      },
    });
  }

  save() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure that you want to save?",
      yesAction: async () => {
        try {
          //TODO: popup and block saving if another transporter with the same name (different guid) is found
          //TODO: popup if multiple drivers share the same name
          this.$store.commit("popup/displayWait", "Saving...");
          console.log(this.$store.getters["storage"]().transporter);
          let transporterClone = lodash.cloneDeep(this.$store.getters["storage"]().transporter);
          transporterClone.metadata = this.$store.getters["user/getUpstreamMetadata"]();
          let json = await this.$store.dispatch(
            "graphQl",
            {
              gql: `mutation transporter($guid: String!, $transporter: TransporterInput!) {
                transporter(guid: $guid, transporter: $transporter) {
                  ...changeTrackingFields
                }
              }${changeTrackingComponentFragment}`,
              variables: { guid: this.$store.state.user.location.guid, transporter: transporterClone as Models.Transporter },
              timeout: 10 * 1000
            },
            { root: true }
          );
          console.log("graphQL: " + JSON.stringify(json));
          let transporter = this.$store.getters["storage"]().transporter as Models.Transporter;
          transporter.changeTracking = json.data.transporter;
          //await this.$store.dispatch("data/set", { objectStore: "Transporter", items: [lodash.cloneDeep(transporter)] });

          //update any intake batches that are using transporter and drivers
          // let intakeBatches = (await this.$store.dispatch("data/get", { objectStore: "IntakeBatch" })) as Models.IntakeBatch[];
          // for (let intakeBatch of intakeBatches) {
          //   if (intakeBatch.transport.length && intakeBatch.transport[0].transporter && intakeBatch.transport[0].transporter.guid === transporter.guid) {
          //     let drivers = lodash.cloneDeep(intakeBatch.transport[0].transporter.drivers); //copy drivers first
          //     intakeBatch.transport[0].transporter = lodash.cloneDeep(transporter); //update transporter
          //     intakeBatch.transport[0].transporter.drivers = drivers; //set drivers to what was previously selected

          //     //check if intakeBatch.transport[0].transporter.drivers match transporter.drivers, update any found
          //     for (let i = 0; i < intakeBatch.transport[0].transporter.drivers.length; ++i) {
          //       //there should only be one driver, just being careful
          //       let driver = transporter.drivers.find((d) => d.guid === intakeBatch.transport[0].transporter?.drivers[i].guid);
          //       if (driver) intakeBatch.transport[0].transporter.drivers[i] = lodash.cloneDeep(driver);
          //     }

          //     await this.$store.dispatch("data/set", { objectStore: "IntakeBatch", items: [lodash.cloneDeep(intakeBatch)] });
          //   }
          // }

          //TODO: update dispatch to abattoir

          this.$router.push({ path: "list/transporters" });
          this.$store.commit("popup/hide");
        } catch (err) {
          console.log(err);
          this.$store.commit("popup/hide");
          this.$store.commit("popup/displayOk", { message: `Error: ${err}` });
        }
      },
    });
  }
}
