
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DimssaButton, { ButtonState } from "@/components/shared/dimssa-button.vue";
import * as Models from "@gigalot/data-models";
import { mapFields } from "@/store";
import { listProcessingResult, listScanningResult } from "@/helpers/graphql-list-items";
import { tagId, visualNumber } from "@/helpers/visual-tag";
import lodash from "lodash";
import { changeTrackingComponentFragment } from "@/helpers/gql";
import { listAnimal } from "@/helpers/graphql-list-items";

@Component({
  components: {
    DimssaButton,
  },
  computed: {
    ...mapFields(["lostTags.notes" /*, "lostTags.refType"*/]),
  },
})
export default class LostTags extends Vue {
  moment = this.$store.state.moment;
  tagId = tagId;
  visualNumber = visualNumber;
  selectReferenceOrDate: "ref" | "date" = "ref";

  getReferenceListItemsButtonState: ButtonState = "ready";

  referenceTypes = [
    { text: "None", value: "none" },
    { text: "New Intake", value: "new" },
    { text: "Count", value: "roll-call" },
    { text: "Dispatch (Group)", value: "dispatch-to-abattoir-gantry" },
    { text: "Dispatch (Single)", value: "dispatch-to-abattoir-processed" },
    { text: "Group Weigh", value: "group-weigh" },
    { text: "Re-implant", value: "reimplant" },
    { text: "Re-process", value: "reprocess" },
    { text: "Re-weigh", value: "reweigh" },
    //{ text: "dispatch-to-kraal", value: "dispatch-to-kraal" },
  ];

  referenceItems: { guid: string; reference?: string; time?: number }[] = [];

  selectedReferenceItem: { guid: string; reference?: string; time?: number } | "" = "";

  referenceDisabled = false;

  animalListItemSearch: string = "";

  @Watch("animalListItemSearch")
  onAnimalListItemSearchChanged(val: any) {
    console.log("onAnimalListItemSearchChanged", this.animalListItemSearch);

    const NUM_CHARS = 3;
    if (this.animalListItemSearch?.length == NUM_CHARS) {
      this.refreshAnimalListItems();
    } else if (this.animalListItemSearch?.length < NUM_CHARS) {
      this.refreshAnimalListItemsButtonState = "disabled";
      this.animalListItems = [];
    }
  }

  changed: boolean = false;

  @Watch("changed")
  onChangeChanged(val: any) {
    this.$store.commit("navFuncs", { save: this.save, back: this.back });
  }

  getConnectedToProxyButtonState: ButtonState = "error";
  get connectedToProxyButtonState() {
    if (this.$store.state.scanner.busyScanning) {
      this.getConnectedToProxyButtonState = "busy";
    } else if (this.$store.state.connectedToProxy) {
      this.getConnectedToProxyButtonState = "ready";
    } else {
      this.getConnectedToProxyButtonState = "disabled";
    }
    return this.getConnectedToProxyButtonState;
  }

  created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$store.commit("updateUserLocationField", { path: "lostTags", value: undefined });
        this.$router.push({ path: "list/lost-tags" });
      },
    });
    const lostTags = this.$store.getters["getUserLocationField"]("lostTags");
    if (!lostTags) throw Error("lostTags not found.");
    if (lostTags.notes === undefined) lostTags.notes = "";
    this.$store.commit("updateUserLocationField", { path: "lostTags", value: lostTags });
    if (lostTags.refGuid) {
      this.selectedReferenceItem = { guid: lostTags.refGuid, reference: lostTags.refText, time: lostTags.time };
      console.log("beforeCreate this.selectedReferenceItem ", JSON.stringify(this.selectedReferenceItem));
      this.referenceItems = [this.selectedReferenceItem];
    }

    if (this.selectedReferenceItem && this.selectedReferenceItem.time !== undefined) {
      this.tempFromDate = this.selectedReferenceItem.time;
      this.tempToDate = this.selectedReferenceItem.time;
    }

    //If there is a refType then enable reference v-autocomplete
    this.referenceDisabled = this.refType ? false : true;

    this.selectReferenceOrDate = this.refType === "none" ? "date" : "ref";

    this.tempLostTagsDate = this.$store.getters["getUserLocationField"]("lostTags.time") ?? Date.now();

    //this.refreshAnimalListItems();
  }

  back() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure? Unsaved work will be lost.",
      yesAction: () => {
        this.$store.commit("updateUserLocationField", { path: "lostTags", value: undefined });
        this.$router.push({ path: "list/lost-tags" });
      },
    });
  }

  async save() {
    const lostTags = this.lostTags;
    if (!lostTags.refType) {
      this.$store.commit("popup/displayOk", `Can not save. No reference type selected.`);
      return;
    } else if (lostTags.refType !== "none" && !lostTags.refGuid) {
      this.$store.commit("popup/displayOk", `Can not save. No reference selected.`);
      return;
    }

    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure that you want to save?",
      yesAction: async () => {
        try {
          this.$store.commit("popup/displayWait", "Saving...");
          let lostTagsClone: Models.LostTags = lodash.cloneDeep(this.$store.getters["storage"]().lostTags);
          lostTagsClone.metadata = this.$store.getters["user/getUpstreamMetadata"]();
          let json = await this.$store.dispatch(
            "graphQl",
            {
              gql: `mutation setLostTags($guid: String!, $lostTags: LostTagsInput!) {
                setLostTags(guid: $guid, lostTags: $lostTags) {
                  ...changeTrackingFields
                }
              }${changeTrackingComponentFragment}`,
              variables: {
                guid: this.$store.state.user.location.guid,
                lostTags: lostTagsClone as Models.LostTags,
              },
              timeout: 10 * 1000
            },
            { root: true }
          );
          console.log("graphQL: " + JSON.stringify(json));
          this.$router.push({ path: "list/lost-tags" });
          this.$store.commit("popup/hide");
        } catch (err) {
          console.log(err);
          this.$store.commit("popup/hide");
          this.$store.commit("popup/displayOk", { message: `Error: ${err}` });
        }
      },
    });
  }

  get lostTags(): Models.LostTags {
    return this.$store.getters["getUserLocationField"]("lostTags");
  }

  get refType() {
    return this.lostTags.refType ?? "";
  }

  set refType(refType: string) {
    const oldRefType = this.$store.getters["getUserLocationField"]("lostTags.refType");
    //if refType is undefined then clear selected reference and empty out items
    //if refType has changed then clear selected reference and empty out items
    this.$store.commit("updateUserLocationField", { path: "lostTags.refType", value: refType });
    if (refType === undefined) {
      this.referenceDisabled = true;
      this.selectedReferenceItem = "";
      this.referenceItems = [];
    } else if (refType !== oldRefType) {
      this.referenceDisabled = false;
      this.selectedReferenceItem = "";
      if (refType === "none") {
        this.tempLostTagsDate = Date.now();
        this.$store.commit("updateUserLocationField", { path: "lostTags.time", value: Date.now() });
      }
      this.getReferenceListItems();
    }
    this.getReferenceListItemsButtonState = "ready";
    this.uiKey++;

    if (refType === "none") this.selectReferenceOrDate = "date";
    else this.selectReferenceOrDate = "ref";
  }

  @Watch("selectedReferenceItem")
  onChangeSelectedReferenceItem(newVal: any, oldVal: any) {
    console.log("newVal: ", newVal);
    console.log("oldVal: ", oldVal);

    if (newVal) {
      this.$store.commit("updateUserLocationField", { path: "lostTags.refGuid", value: newVal.guid });
      this.$store.commit("updateUserLocationField", { path: "lostTags.refText", value: newVal.reference });
      this.$store.commit("updateUserLocationField", { path: "lostTags.time", value: newVal.time });
    } else {
      this.$store.commit("updateUserLocationField", { path: "lostTags.refGuid", value: undefined });
      this.$store.commit("updateUserLocationField", { path: "lostTags.refText", value: undefined });
      this.$store.commit("updateUserLocationField", { path: "lostTags.time", value: undefined });
      this.tempFromDate = this.moment(Date.now()).subtract(1, "years").valueOf();
      this.tempToDate = this.moment(Date.now()).valueOf();
      this.getReferenceListItems();
    }
  }

  async getReferenceListItems() {
    this.referenceItems = [];
    if (!this.lostTags.refType) {
      console.log("getReferenceListItems(): no refType, returning.");
      return;
    }
    try {
      this.getReferenceListItemsButtonState = "busy";
      switch (this.lostTags.refType) {
        case "roll-call":
        case "dispatch-to-abattoir-gantry":
        case "group-weigh": {
          const scanningResults = await listScanningResult(this.lostTags.refType, this.tempFromDate, this.tempToDate);
          this.referenceItems = scanningResults.map((s: any) => ({ guid: s.guid, reference: s.reference, time: s.time }));
          break;
        }
        case "new":
        case "dispatch-to-abattoir-processed":
        case "reimplant":
        case "reprocess":
        case "reweigh": {
          const processingResults = await listProcessingResult(this.lostTags.refType, this.tempFromDate, this.tempToDate);
          this.referenceItems = processingResults.map((s: any) => ({ guid: s.guid, reference: s.reference, time: s.time }));
          break;
        }
      }

      if (this.selectedReferenceItem && !this.referenceItems.find((ri) => ri.guid === this.selectedReferenceItem)) {
        this.referenceItems = [this.selectedReferenceItem, ...this.referenceItems];
      }

      this.getReferenceListItemsButtonState = "success";
    } catch (err) {
      this.getReferenceListItemsButtonState = "error";
      console.error(err);
    }
  }

  ////////
  // Animals

  selectedAnimalListItem: any | "" = "";

  animalListItems: {
    typename: string;
    guid: string;
    sgtin?: string;
    owner?: string;
    breed?: string;
    gender?: string;
    tagId?: string;
  }[] = [];

  refreshAnimalListItemsButtonState: ButtonState = "disabled";
  refreshAnimalListItemsErrorMessage: string = "";
  async refreshAnimalListItems() {
    try {
      this.refreshAnimalListItemsButtonState = "busy";
      this.refreshAnimalListItemsErrorMessage = "";

      this.animalListItems = await listAnimal(this.animalListItemSearch);

      this.refreshAnimalListItemsButtonState = "success";
    } catch (err) {
      this.animalListItems = [];
      console.error("refreshAnimalListItems() error:");
      console.error(err);
      this.refreshAnimalListItemsErrorMessage = (err as any)?.toString() ?? "no error message found";
      this.refreshAnimalListItemsButtonState = "error";
    }
  }

  // Animals
  ////////
  uiKey = 0;
  addTag() {
    if (!this.selectedAnimalListItem?.sgtin) {
      console.log("addTag: no selected animal sgtin, returning.");
      return;
    }

    this.changed = true;

    const lostTags = this.lostTags.lostTags;

    if (lostTags.find((t) => t.sgtin === this.selectedAnimalListItem?.sgtin)) {
      console.log("Tag already in list, returning.");
      return;
    }

    lostTags.push({
      sgtin: this.selectedAnimalListItem.sgtin,
      breed: this.selectedAnimalListItem.breed,
      gender: this.selectedAnimalListItem.gender,
      owner: this.selectedAnimalListItem.owner,
    });
    this.$store.commit("updateUserLocationField", { path: "lostTags.lostTags", value: lostTags });
    this.selectedAnimalListItem = "";
    this.uiKey++;
  }

  removeTag(tag: { sgtin: string }) {
    const lostTags = this.lostTags.lostTags;
    const index = lostTags.findIndex((t) => t.sgtin === tag.sgtin);
    if (index > -1) {
      lostTags.splice(index, 1);
      this.$store.commit("updateUserLocationField", { path: "lostTags.lostTags", value: lostTags });
      this.changed = true;
    }
    this.uiKey++;
  }

  async scanTag() {
    let sgtin: string = await this.$store.dispatch("scanner/scanSgtin");
    if (sgtin.startsWith("ERROR")) {
      this.$store.commit("popup/displayOk", sgtin);
    } else {
      sgtin = sgtin.replace(/"/g, "");
      //get guid for animal from animal list
      let animalToFind = this.animalListItems.find((a) => a.sgtin === sgtin);
      if (animalToFind) {
        this.selectedAnimalListItem = animalToFind;
        this.addTag();
      } else {
        //animal not found
        this.$store.commit("popup/displayOk", "Animal not found");
      }
    }
  }

  tempFromDate: number = this.moment(Date.now()).subtract(1, "years").valueOf();

  tempToDate: number = this.moment(Date.now()).valueOf();

  fromDateMenu: boolean = false;
  get fromDate() {
    return this.moment(new Date(this.tempFromDate)).format("YYYY-MM-DD");
  }

  set fromDate(fromDate: string) {
    this.tempFromDate = this.moment(fromDate).valueOf();
  }

  toDateMenu: boolean = false;
  get toDate() {
    return this.moment(new Date(this.tempToDate)).format("YYYY-MM-DD");
  }

  set toDate(toDate: string) {
    this.tempToDate = this.moment(toDate).valueOf();
  }

  //date selected when "none" refType selected
  tempLostTagsDate: number = Date.now();
  lostTagsDateMenu: boolean = false;
  get lostTagsDate() {
    // const lostTagsTime = this.$store.getters["getUserLocationField"]("lostTags.time");
    // return this.moment(new Date(lostTagsTime ?? Date.now())).format("YYYY-MM-DD");
    return this.moment(new Date(this.tempLostTagsDate)).format("YYYY-MM-DD");
  }

  set lostTagsDate(lostTagsDate: string) {
    //this.$store.commit("updateUserLocationField", { path: "lostTags.time", value: this.moment(lostTagsDate).valueOf() });
    this.tempLostTagsDate = this.moment(lostTagsDate).valueOf();
    this.$store.commit("updateUserLocationField", { path: "lostTags.time", value: this.tempLostTagsDate });
  }
}
