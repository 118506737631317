
import { Component, Vue, Watch } from "vue-property-decorator";
import DimssaButton from "@/components/shared/dimssa-button.vue";
import * as Models from "@gigalot/data-models";
import { mapFields } from "@/store";
import lodash from "lodash";
import { changeTrackingComponentFragment } from "@/helpers/gql";

@Component({
  components: {
    DimssaButton,
  },
  computed: {
    ...mapFields(["buyer.name", "buyer.active"]),
    ...mapFields({ companyName: "buyer.companies[0].name" }),
  },
})
export default class Buyer extends Vue {
  get buyer(): Models.Buyer {
    return this.$store.getters["storage"]().buyer;
  }

  changed: boolean = false;

  @Watch("changed")
  onChangeChanged(val: any) {
    this.$store.commit("navFuncs", { save: this.save, back: this.back });
  }

  async created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$store.commit("updateUserLocationField", { path: "buyer", value: {} });
        this.$router.push({ path: "list/buyers" });
      },
    });
  }

  back() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure? Unsaved work will be lost.",
      yesAction: () => {
        this.$store.commit("updateUserLocationField", { path: "buyer", value: {} });
        //this.$router.go(-1);
        this.$router.push({ path: "list/buyers" });
      },
    });
  }

  save() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure that you want to save?",
      yesAction: async () => {
        try {
          //TODO: popup and block saving if another buyer with the same name (different guid) is found
          this.$store.commit("popup/displayWait", "Saving...");
          console.log(this.$store.getters["storage"]().buyer);
          let buyerClone = lodash.cloneDeep(this.$store.getters["storage"]().buyer);
          buyerClone.metadata = this.$store.getters["user/getUpstreamMetadata"]();
          let json = await this.$store.dispatch(
            "graphQl",
            {
              gql: `mutation setBuyer($guid: String!, $buyer: BuyerInput!) {
                setBuyer(guid: $guid, buyer: $buyer) {
                  ...changeTrackingFields
                }
              }${changeTrackingComponentFragment}`,
              variables: { guid: this.$store.state.user.location.guid, buyer: buyerClone as Models.Buyer },
              timeout: 10 * 1000
            },
            { root: true }
          );
          console.log("graphQL: " + JSON.stringify(json));
          let buyer = this.$store.getters["storage"]().buyer as Models.Buyer;
          buyer.changeTracking = json.data.buyer;
          //await this.$store.dispatch("data/set", { objectStore: "Buyer", items: [lodash.cloneDeep(buyer)] });

          // let intakeBatches = (await this.$store.dispatch("data/get", { objectStore: "IntakeBatch" })) as Models.IntakeBatch[];
          // for (let intakeBatch of intakeBatches) {
          //   if (intakeBatch.transport.length && intakeBatch.transport[0].buyer && intakeBatch.transport[0].buyer.guid === buyer.guid) {
          //     intakeBatch.transport[0].buyer = lodash.cloneDeep(buyer);
          //     await this.$store.dispatch("data/set", { objectStore: "IntakeBatch", items: [lodash.cloneDeep(intakeBatch)] });
          //   }
          // }

          this.$router.push({ path: "list/buyers" });
          this.$store.commit("popup/hide");
        } catch (err) {
          console.log(err);
          this.$store.commit("popup/hide");
          this.$store.commit("popup/displayOk", { message: `Error: ${err}` });
        }
      },
    });
  }
}
