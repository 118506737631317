
import { Component, Vue, Watch } from "vue-property-decorator";
import DimssaButton from "@/components/shared/dimssa-button.vue";
import lodash from "lodash";
import * as Models from "@gigalot/data-models";
import { changeTrackingComponentFragment } from "@/helpers/gql";

@Component({
  components: {
    DimssaButton,
  },
})
export default class MaterialCost extends Vue {
  back() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure? Unsaved work will be lost.",
      yesAction: () => {
        this.$router.push({ path: "list/material-costs" });
      },
    });
  }

  async save() {
    let self = this;
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure that you want to save?",
      yesAction: async () => {
        try {
          this.$store.commit("popup/displayWait", "Saving...");
          if (self.costMaterial) {
            await self.saveCost(self.costMaterial);
            this.$router.push({ path: "list/material-costs" });
          } else throw Error("no costMaterial");
          this.$store.commit("popup/hide");
        } catch (err) {
          console.log(err);
          this.$store.commit("popup/hide");
          this.$store.commit("popup/displayOk", { message: `Error: ${err}` });
        }
      },
    });
  }

  async saveCost(cost: Models.CostMaterial) {
    if (!cost) throw Error("saveCost cost is undefined");
    let costClone = lodash.cloneDeep(cost);
    costClone.metadata = this.$store.getters["user/getUpstreamMetadata"]();
    let json = await this.$store.dispatch(
      "graphQl",
      {
        gql: `mutation costMaterial($guid: String!, $cost: CostMaterialInput!) {
                costMaterial(guid: $guid, cost: $cost) {
                  ...changeTrackingFields
                }
              }${changeTrackingComponentFragment}`,
        variables: { guid: this.$store.state.user.location.guid, cost: costClone },
      },
      { root: true }
    );
    let costMaterial = this.costMaterial as Models.CostMaterial;
    costMaterial.changeTracking = json.data.costMaterial;
    //await this.$store.dispatch("data/set", { objectStore: "CostMaterial", items: [lodash.cloneDeep(costMaterial)] });
  }

  copyItem(item: any) {
    this.tempSince = item.since;
    this.tempCost = item.value;
    // this.tempPer = item.per;
    // this.tempUnit = item.unit;
  }

  deleteItem(item: any) {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure?",
      yesAction: () => {
        if (!this.costMaterial) return;
        const index = this.costMaterial.history.indexOf(item);
        this.costMaterial.history.splice(index, 1);
      },
    });
  }

  async created() {
    this.$store.commit("navFuncs", { save: this.save, back: this.back });
    console.log(JSON.stringify(this.material));
    throw Error("TODO: fix data/get");
    // let costMaterials: Models.CostMaterial[] = (await this.$store.dispatch("data/get", {
    //   objectStore: "CostMaterial",
    // })) as Models.CostMaterial[];
    // console.log(JSON.stringify(costMaterials));
    // this.costMaterial = lodash.cloneDeep(lodash.find(costMaterials, (cost) => cost.guid === this.material.guid) || "");
    // if (!this.costMaterial) {
    //   try {
    //     console.log("Material cost not found, adding to server");
    //     this.costMaterial = new Models.CostMaterial();
    //     this.costMaterial.guid = this.material.guid;
    //     await this.saveCost(this.costMaterial);
    //     console.log("Material cost added to server");
    //   } catch (err) {
    //     console.log("Error adding material cost to server");
    //   }
    // } else {
    //   console.log("Existing material cost found");
    // }
  }

  get material() {
    return this.$store.getters["storage"]().material;
  }

  addCost() {
    if (this.costMaterial) {
      this.$store.commit("snackbar", { visible: false });
      if (this.tempCost) {
        console.log(this.tempSince);
        let historyItemIndex = lodash.findIndex(this.costMaterial.history, { since: this.tempSince });
        if (historyItemIndex >= 0) this.costMaterial.history[historyItemIndex].value = this.tempCost;
        else this.costMaterial.history.push({ value: this.tempCost, per: 1.0, unit: "tonne", since: this.tempSince });
      } else {
        this.$store.commit("snackbar", { message: `Invalid Cost`, timeout: 5000 });
        //this.$store.commit("popup/displayOk", `Invalid Cost`);
      }
    } else throw Error("Tried to add cost to costMaterial which doesn't exist!");
  }

  get moment() {
    return this.$store.state.moment;
  }

  costMaterial: Models.CostMaterial | "" = "";

  tempCost: number | "" = "";
  tempSince: number = this.moment(this.moment(Date.now()).format("YYYY-MM-DD")).valueOf();

  sinceDateMenu: boolean = false;
  get sinceDate() {
    return this.moment(new Date(this.tempSince)).format("YYYY-MM-DD");
  }

  set sinceDate(sinceDate: string) {
    this.tempSince = this.moment(sinceDate).valueOf();
  }
}
