import store from "@/store";

/*
countries = [
  {
    name: "Country name",
    provinces: [
      {
        name: "Province name",
        towns: [
          { name: "Town name" }
        ]
      }
    ]
  }
]
*/

//add firebase callback
//get url for cloud function
//fetch json from cloud function
//populate countries

async function getTownsProvincesCountries() {
  //try {
  const url = store.getters["urlTownsProvincesCountries"]();
  const res = await fetch(url);
  const countries: {
    name: string,
    provinces: {
      name: string;
      towns: {
        name: string;
      }[];
    }[];
  }[] = [];
  const dict: { [country: string]: { [province: string]: { [town: string]: {}; }; }; } = {};
  if (res.ok) {
    const d = await res.json();
    for (const i of d) {
      //[[town, province, country]]
      const country = i[2];
      const province = i[1];
      const town = i[0];
      if (!dict[country]) dict[country] = {};
      if (!dict[country][province]) dict[country][province] = {};
      if (!dict[country][province][town]) dict[country][province][town] = {};
    }
    for (const country in dict) { //in for keys
      const provinces = [];
      for (const province in dict[country]) {
        const towns = [];
        for (const town in dict[country][province]) {
          towns.push(town);
        }
        provinces.push({
          name: province,
          towns: towns.map(t => ({ name: t }))
        });
      }
      countries.push({
        name: country,
        provinces: provinces
      });
    }
    return countries;
  } else {
    throw Error(`response not ok: ${await res.text()}`);
  }
  // } catch (err) {
  //   console.error("Error getting towns, provinces and countries: ", err);
  // }
}

export async function updateTownsProvincesCountries() {
  const t = await getTownsProvincesCountries();
  if (t) {
    countries = t;
    console.dir(countries);
  }
  else throw Error(`Tried to get towns, provinces and countries but got: ${t}`);
}

// store.dispatch("user/addFirebaseCallback", async () => {
//   const t = await getTownsProvincesCountries();
//   if (t) countries = t;
//   else console.error("Tried to get towns, provinces and countries but got: ", t);
// });

export let countries = [
  {
    name: "South Africa",
    provinces: [
      {
        name: "Eastern Cape",
        towns: [
          { name: "Aberdeen" },
          { name: "Addo" },
          { name: "Adelaide" },
          { name: "Adendorp" },
          { name: "Alderley" },
          { name: "Alexandria" },
          { name: "Alice" },
          { name: "Alicedale" },
          { name: "Aliwal North" },
          { name: "Bailey" },
          { name: "Balfour" },
          { name: "Barakke" },
          { name: "Barkly East" },
          { name: "Baroe" },
          { name: "Bathurst" },
          { name: "Bedford" },
          { name: "Behulpsaam" },
          { name: "Bell" },
          { name: "Bellevue" },
          { name: "Berlin" },
          { name: "Bethelsdorp" },
          { name: "Bhisho" },
          { name: "Bityi" },
          { name: "Bluewater Bay" },
          { name: "Bonza Bay" },
          { name: "Braunschweig" },
          { name: "Burgersdorp" },
          { name: "Butterworth" },
          { name: "Cala" },
          { name: "Cambria" },
          { name: "Cannon Rocks" },
          { name: "Cape St. Francis" },
          { name: "Cathcart" },
          { name: "Chalumna" },
          { name: "Chintsa" },
          { name: "Clarkebury" },
          { name: "Clifford" },
          { name: "Coega" },
          { name: "Coffee Bay" },
          { name: "Cofimvaba" },
          { name: "Coghlan" },
          { name: "Colchester" },
          { name: "Coleford" },
          { name: "Cookhouse" },
          { name: "Cradock" },
          { name: "Despatch" },
          { name: "Döhne" },
          { name: "Dordrecht" },
          { name: "Dutywa" },
          { name: "East London" },
          { name: "Elliot" },
          { name: "Engcobo" },
          { name: "Flagstaff" },
          { name: "Fort Beaufort" },
          { name: "Gcuwa" },
          { name: "Gonubie" },
          { name: "Graaff-Reinet" },
          { name: "Grahamstown" },
          { name: "Hankey" },
          { name: "Hofmeyr" },
          { name: "Humansdorp" },
          { name: "Ibhayi" },
          { name: "Indwe" },
          { name: "Jansenville" },
          { name: "Jeffreys Bay" },
          { name: "Kenton-on-Sea" },
          { name: "King William's Town" },
          { name: "Kirkwood" },
          { name: "Lady Frere" },
          { name: "Lady Grey" },
          { name: "Maclear" },
          { name: "Matatiele" },
          { name: "Middelburg" },
          { name: "Molteno" },
          { name: "Mount Fletcher" },
          { name: "Mthatha" },
          { name: "Nanaga" },
          { name: "Nieu-Bethesda" },
          { name: "Oyster Bay" },
          { name: "Patensie" },
          { name: "Paterson" },
          { name: "Port Alfred" },
          { name: "Port Elizabeth" },
          { name: "Port St. Johns" },
          { name: "Queenstown" },
          { name: "Somerset East" },
          { name: "St Francis Bay" },
          { name: "St Georges Strand" },
          { name: "Sterkstroom" },
          { name: "Steynsburg" },
          { name: "Stutterheim" },
          { name: "Swartkops" },
          { name: "Tarkastad" },
          { name: "Uitenhage" },
          { name: "Umthatha" },
          { name: "Venterstad" },
          { name: "Whittlesea" },
          { name: "Zwelitsha" },
        ]
      }, {
        name: "Free State",
        towns: [
          { name: "Aberfeldy" },
          { name: "Allandale" },
          { name: "Allanridge" },
          { name: "Allep" },
          { name: "Arlington" },
          { name: "Bethlehem" },
          { name: "Bethulie" },
          { name: "Bloemfontein" },
          { name: "Boompie Alleen" },
          { name: "Boshof" },
          { name: "Bothaville" },
          { name: "Botshabelo" },
          { name: "Brandfort" },
          { name: "Bultfontein" },
          { name: "Clarens" },
          { name: "Clocolan" },
          { name: "Cornelia" },
          { name: "Dealesville" },
          { name: "Deneysville" },
          { name: "Dewetsdorp" },
          { name: "Edenburg" },
          { name: "Edenville" },
          { name: "Excelsior" },
          { name: "Fauresmith" },
          { name: "Ficksburg" },
          { name: "Fouriesburg" },
          { name: "Frankfort" },
          { name: "Gariep Dam" },
          { name: "Harrismith" },
          { name: "Heilbron" },
          { name: "Hennenman" },
          { name: "Hertzogville" },
          { name: "Hobhouse" },
          { name: "Hoopstad" },
          { name: "Jacobsdal" },
          { name: "Jagersfontein" },
          { name: "Kestell" },
          { name: "Kgotsong" },
          { name: "Koffiefontein" },
          { name: "Koppies" },
          { name: "Kroonstad" },
          { name: "Ladybrand" },
          { name: "Lindley" },
          { name: "Luckhoff" },
          { name: "Makeleketla" },
          { name: "Marquard" },
          { name: "Memel" },
          { name: "Odendaalsrus" },
          { name: "Oranjeville" },
          { name: "Parys" },
          { name: "Paul Roux" },
          { name: "Petrus Steyn" },
          { name: "Petrusburg" },
          { name: "Philippolis" },
          { name: "Phuthaditjhaba" },
          { name: "Reddersburg" },
          { name: "Reitz" },
          { name: "Rosendal" },
          { name: "Rouxville" },
          { name: "Sasolburg" },
          { name: "Senekal" },
          { name: "Smithfield" },
          { name: "Soutpan" },
          { name: "Springfontein" },
          { name: "Steynsrus" },
          { name: "Swinburne" },
          { name: "Thaba Nchu" },
          { name: "Theunissen" },
          { name: "Trompsburg" },
          { name: "Tweeling" },
          { name: "Tweespruit" },
          { name: "Van Stadensrus" },
          { name: "Ventersburg" },
          { name: "Verkeerdevlei" },
          { name: "Viljoenskroon" },
          { name: "Villiers" },
          { name: "Vrede" },
          { name: "Vredefort" },
          { name: "Warden" },
          { name: "Welkom" },
          { name: "Wepener" },
          { name: "Wesselsbron" },
          { name: "Winburg" },
          { name: "Zastron" },
        ]
      }, {
        name: "Gauteng",
        towns: [
          { name: "Akasia" },
          { name: "Alberton" },
          { name: "Alexandra" },
          { name: "Atteridgeville" },
          //{ name: "Bank" },
          { name: "Bapsfontein" },
          { name: "Benoni" },
          { name: "Boipatong" },
          { name: "Boksburg" },
          { name: "Bophelong" },
          { name: "Brakpan" },
          { name: "Bronkhorstspruit" },
          { name: "Carletonville" },
          { name: "Centurion" },
          { name: "Chantelle" },
          { name: "Cullinan" },
          { name: "Daveyton" },
          { name: "Devon" },
          { name: "Duduza" },
          { name: "Edenvale" },
          { name: "Evaton" },
          { name: "Fochville" },
          { name: "Germiston" },
          { name: "Hammanskraal" },
          { name: "Heidelberg" },
          { name: "Henley on Klip" },
          { name: "Irene" },
          { name: "Isando" },
          { name: "Johannesburg" },
          { name: "Katlehong" },
          { name: "Kempton Park" },
          { name: "Kromdraai" },
          { name: "Krugersdorp" },
          { name: "KwaThema" },
          { name: "Lenasia" },
          { name: "Lyttelton" },
          { name: "Magaliesburg" },
          { name: "Mamelodi" },
          { name: "Meyerton" },
          { name: "Midrand" },
          { name: "Muldersdrift" },
          { name: "Nigel" },
          { name: "Orchards" },
          { name: "Pretoria" },
          { name: "Randburg" },
          { name: "Randfontein" },
          { name: "Ratanda" },
          { name: "Roodepoort" },
          { name: "Rooihuiskraal" },
          { name: "Sandton" },
          { name: "Sebokeng" },
          { name: "Sharpeville" },
          { name: "Soshanguve" },
          { name: "Soweto" },
          { name: "Springs" },
          { name: "Tembisa" },
          { name: "Thokoza" },
          { name: "Tsakane" },
          { name: "Vanderbijlpark" },
          { name: "Vereeniging" },
          { name: "Vosloorus" },
          { name: "Westonaria" },
          { name: "Wedela" },
        ]
      }, {
        name: "KwaZulu-Natal",
        towns: [
          { name: "Ahrens" },
          { name: "Amanzimtoti" },
          { name: "Anerley" },
          { name: "Ashburton" },
          { name: "Babanango" },
          { name: "Balgowan" },
          { name: "Ballengeich" },
          { name: "Ballito" },
          { name: "Bazley" },
          { name: "Bergville" },
          { name: "Besters" },
          { name: "Biggarsberg" },
          { name: "Boston" },
          { name: "Bulwer" },
          { name: "Calvert" },
          { name: "Camperdown" },
          { name: "Candover" },
          { name: "Cape Vidal" },
          { name: "Catalina Bay" },
          { name: "Cato Ridge" },
          { name: "Cedarville" },
          { name: "Charlestown" },
          { name: "Clansthal" },
          { name: "Clermont" },
          { name: "Colenso" },
          { name: "Dalton" },
          { name: "Dannhauser" },
          { name: "Darnall" },
          { name: "Doonside" },
          { name: "Drummond" },
          { name: "Dundee" },
          { name: "Durban" },
          { name: "ekuPhakameni" },
          { name: "Elandslaagte" },
          { name: "Empangeni" },
          { name: "Eshowe" },
          { name: "Estcourt" },
          { name: "Franklin" },
          { name: "Glencoe" },
          { name: "Greytown" },
          { name: "Hattingspruit" },
          { name: "Hibberdene" },
          { name: "Hillcrest" },
          { name: "Hilton" },
          { name: "Himeville" },
          { name: "Hluhluwe" },
          { name: "Howick" },
          { name: "Ifafa Beach" },
          { name: "Illovo Beach" },
          { name: "Impendle" },
          { name: "Inanda" },
          { name: "Ingwavuma" },
          { name: "Isipingo" },
          { name: "Ixopo" },
          { name: "Karridene" },
          { name: "Kingsburgh" },
          { name: "Kloof" },
          { name: "Kokstad" },
          { name: "KwaDukuza" },
          { name: "KwaMashu" },
          { name: "La Lucia" },
          { name: "La Mercy" },
          { name: "Ladysmith" },
          { name: "Louwsburg" },
          { name: "Madadeni" },
          { name: "Magabeni" },
          { name: "Mahlabatini" },
          { name: "Mandini" },
          { name: "Margate" },
          { name: "Melmoth" },
          { name: "Melville" },
          { name: "Merrivale" },
          { name: "Mkuze" },
          { name: "Mooirivier" },
          { name: "Mount Edgecombe" },
          { name: "Mtubatuba" },
          { name: "Mtunzini" },
          { name: "Muden" },
          { name: "New Germany" },
          { name: "New Hanover" },
          { name: "Newcastle" },
          { name: "Nongoma" },
          { name: "Nottingham Road" },
          { name: "Palm Beach" },
          { name: "Park Rynie" },
          { name: "Paulpietersburg" },
          { name: "Pennington" },
          { name: "Pietermaritzburg" },
          { name: "Pinetown" },
          { name: "Pomeroy" },
          { name: "Pongola" },
          { name: "Port Edward" },
          { name: "Port Shepstone" },
          { name: "Queensburgh" },
          { name: "Ramsgate" },
          { name: "Richards Bay" },
          { name: "Salt Rock" },
          { name: "Scottburgh" },
          { name: "Sezela" },
          { name: "Shelly Beach" },
          { name: "Southbroom" },
          { name: "St Lucia" },
          { name: "St Michael's-on-sea" },
          { name: "Tongaat" },
          { name: "Tugela Ferry" },
          { name: "Ubombo" },
          { name: "Ulundi" },
          { name: "Umbongintwini" },
          { name: "Umdloti" },
          { name: "Umgababa" },
          { name: "Umhlanga Rocks" },
          { name: "Umkhomazi" },
          { name: "Umlazi" },
          { name: "Umtentweni" },
          { name: "Umzinto" },
          { name: "Umzumbe" },
          { name: "Underberg" },
          { name: "Utrecht" },
          { name: "Uvongo" },
          { name: "Van Reenen" },
          { name: "Verulam" },
          { name: "Virginia" },
          { name: "Vryheid" },
          { name: "Warner Beach" },
          { name: "Wartburg" },
          { name: "Wasbank" },
          { name: "Weenen" },
          { name: "Westville" },
          { name: "Winkelspruit" },
          { name: "Winterton" },
          { name: "York" },
        ]
      }, {
        name: "Limpopo",
        towns: [
          { name: "Alldays" },
          { name: "Baltimore" },
          { name: "Bandelierkop" },
          { name: "Bandur" },
          { name: "Beauty" },
          { name: "Beitbridge" },
          { name: "Bela-Bela" },
          { name: "Burgersfort" },
          { name: "Carlow" },
          { name: "Chuniespoort" },
          { name: "Duiwelskloof" },
          { name: "Giyani" },
          { name: "Gravelotte" },
          { name: "Groblersdal" },
          { name: "Haenertsburg" },
          { name: "Hoedspruit" },
          { name: "Lephalale" },
          { name: "Louis Trichardt" },
          { name: "Marble Hall" },
          { name: "Modimolle" },
          { name: "Mokopane" },
          { name: "Musina" },
          { name: "Naboomspruit" },
          { name: "Phalaborwa" },
          { name: "Polokwane" },
          { name: "Shawela" },
          { name: "Thabazimbi" },
          { name: "Thohoyandou" },
          { name: "Vaalwater" },
          { name: "Vivo" },
        ]
      }, {
        name: "Mpumalanga",
        towns: [
          { name: "Acornhoek" },
          { name: "Amersfoort" },
          { name: "Amsterdam" },
          { name: "Badplaas" },
          { name: "Balfour" },
          { name: "Balmoral" },
          { name: "Bankkop" },
          { name: "Barberton" },
          { name: "Belfast" },
          { name: "Berbice" },
          { name: "Bethal" },
          { name: "Bettiesdam" },
          { name: "Bosbokrand" },
          { name: "Breyten" },
          { name: "Carolina" },
          { name: "Charl Cilliers" },
          { name: "Chrissiesmeer" },
          { name: "Clewer" },
          { name: "Coalville" },
          { name: "Commondale" },
          { name: "Cork" },
          { name: "Delmas" },
          { name: "Dullstroom" },
          { name: "Ermelo" },
          { name: "Graskop" },
          { name: "Greylingstad" },
          { name: "Grootvlei" },
          { name: "Hazyview" },
          { name: "Hectorspruit" },
          { name: "Hendrina" },
          { name: "Kaapmuiden" },
          { name: "Kinross" },
          { name: "Komatipoort" },
          { name: "Kriel" },
          { name: "KwaMhlanga" },
          { name: "Loopspruit" },
          { name: "Lydenburg" },
          { name: "Machadodorp" },
          { name: "Malelane" },
          { name: "Mbombela" },
          { name: "Middelburg" },
          { name: "Morgenzon" },
          { name: "Ogies" },
          { name: "Ohrigstad" },
          { name: "Perdekop" },
          { name: "Piet Retief" },
          { name: "Pilgrim's Rest" },
          { name: "Sabie" },
          { name: "Secunda" },
          { name: "Standerton" },
          { name: "Trichardt" },
          { name: "Vaalbank" },
          { name: "Volksrust" },
          { name: "Wakkerstroom" },
          { name: "Waterval Boven" },
          { name: "Waterval Onder" },
          { name: "White River" },
          { name: "Witbank" },
        ]
      }, {
        name: "North West",
        towns: [
          { name: "Albertshoek" },
          { name: "Alettasrus" },
          { name: "Amalia" },
          { name: "Babelegi" },
          { name: "Bakerville" },
          { name: "Barberspan" },
          { name: "Beestekraal" },
          { name: "Bethanie" },
          { name: "Bewley" },
          { name: "Biesiesvlei" },
          { name: "Bloemhof" },
          { name: "Bray" },
          { name: "Brits" },
          { name: "Broederstroom" },
          { name: "Carlsonia" },
          { name: "Christiana" },
          { name: "Coligny" },
          { name: "Delareyville" },
          { name: "Derby" },
          { name: "Ga-Rankuwa" },
          { name: "Ganyesa" },
          { name: "Groot Marico" },
          { name: "Hartbeespoort" },
          { name: "Klerksdorp" },
          { name: "Lichtenburg" },
          { name: "Mafikeng" },
          { name: "Mareetsane" },
          { name: "Mmabatho" },
          { name: "Orkney" },
          { name: "Ottosdal" },
          { name: "Potchefstroom" },
          { name: "Rustenburg" },
          { name: "Sannieshof" },
          { name: "Schweizer-Reneke" },
          { name: "Setlagole" },
          { name: "Stella" },
          { name: "Stilfontein" },
          { name: "Ventersdorp" },
          { name: "Vryburg" },
          { name: "Wolmaransstad" },
          { name: "Zeerust" },
        ]
      }, {
        name: "Northern Cape",
        towns: [
          { name: "Aggeneys" },
          { name: "Alexander Bay" },
          { name: "Askham" },
          { name: "Augrabies" },
          { name: "Barkly West" },
          { name: "Bekker" },
          { name: "Belmont" },
          { name: "Bermolli" },
          { name: "Biesiespoort" },
          { name: "Britstown" },
          { name: "Calvinia" },
          { name: "Campbell" },
          { name: "Carlton" },
          { name: "Carnarvon" },
          { name: "Carolusberg" },
          { name: "Colesberg" },
          { name: "Colston" },
          { name: "Concordia" },
          { name: "Copperton" },
          { name: "Danielskuil" },
          { name: "De Aar" },
          { name: "Delportshoop" },
          { name: "Dibeng" },
          { name: "Dingleton" },
          { name: "Douglas" },
          { name: "Fraserburg" },
          { name: "Griekwastad" },
          { name: "Groblershoop" },
          { name: "Hanover" },
          { name: "Hartswater" },
          { name: "Hopetown" },
          { name: "Jan Kempdorp" },
          { name: "Kimberley" },
          { name: "Modder River" },
          { name: "Norvalspont" },
          { name: "Noupoort" },
          { name: "Orania" },
          { name: "Philipstown" },
          { name: "Prieska" },
          { name: "Richmond" },
          { name: "Springbok" },
          { name: "Strydenburg" },
          { name: "Upington" },
          { name: "Victoria West" },
          { name: "Warrenton" },
          { name: "Williston" },
          { name: "Windsorton" },
        ]
      }, {
        name: "Western Cape",
        towns: [
          { name: "Aan de Doorns" },
          { name: "Abbotsdale" },
          { name: "Agulhas" },
          { name: "Albertinia" },
          { name: "Amalienstein" },
          { name: "Arniston" },
          { name: "Ashton" },
          { name: "Atlantis" },
          { name: "Aurora" },
          { name: "Baardskeerdersbos" },
          { name: "Barrington" },
          { name: "Barrydale" },
          { name: "Baviaan" },
          { name: "Beaufort West" },
          { name: "Bellville" },
          { name: "Berea" },
          { name: "Bergplaas" },
          { name: "Bergrivier" },
          { name: "Betty's Bay" },
          { name: "Biesiesfontein" },
          { name: "Bitterfontein" },
          { name: "Bonnievale" },
          { name: "Brackenfell" },
          { name: "Bredasdorp" },
          { name: "Buffelsjagbaai" },
          { name: "Caledon" },
          { name: "Calitzdorp" },
          { name: "Camps Bay" },
          { name: "Cape Town" },
          { name: "Ceres" },
          { name: "Churchaven" },
          { name: "Citrusdal" },
          { name: "Clanwilliam" },
          { name: "Claremont" },
          { name: "Clarkson" },
          { name: "Clifton" },
          { name: "Constantia" },
          { name: "Darling" },
          { name: "De Doorns" },
          { name: "De Kelders" },
          { name: "De Rust" },
          { name: "Doringbaai" },
          { name: "Durbanville" },
          { name: "Dysselsdorp" },
          { name: "Eendekuil" },
          { name: "Elandsbaai" },
          { name: "Elgin" },
          { name: "Elim" },
          { name: "Faure" },
          { name: "Firgrove" },
          { name: "Fish Hoek" },
          { name: "Fisherhaven" },
          { name: "Franschhoek" },
          { name: "Franskraal" },
          { name: "Gansbaai" },
          { name: "Genadendal" },
          { name: "George" },
          { name: "Gordon's Bay" },
          { name: "Gouda" },
          { name: "Graafwater" },
          { name: "Grabouw" },
          { name: "Greyton" },
          { name: "Heidelberg" },
          { name: "Hermanus" },
          { name: "Hopefield" },
          { name: "Jamestown" },
          { name: "Keurboomstrand" },
          { name: "Khayelitsha" },
          { name: "Klapmuts" },
          { name: "Knysna" },
          { name: "Kommetjie" },
          { name: "Kraaifontein" },
          { name: "Kuils River" },
          { name: "Laingsburg" },
          { name: "Lambert's Bay" },
          { name: "Matjiesfontein" },
          { name: "Melkbosstrand" },
          { name: "Milnerton" },
          { name: "Mossel Bay" },
          { name: "Muizenberg" },
          { name: "Noordhoek" },
          { name: "Oudtshoorn" },
          { name: "Paarl" },
          { name: "Piketberg" },
          { name: "Plettenberg Bay" },
          { name: "Pniel" },
          { name: "Prins Albert" },
          { name: "Riebeek Kasteel" },
          { name: "Robertson" },
          { name: "Somerset West" },
          { name: "Stellenbosch" },
          { name: "Strand" },
          { name: "Swartberg" },
          { name: "Swellendam" },
          { name: "Tulbagh" },
          { name: "Uniondale" },
          { name: "Wellington" },
          { name: "Wilderness" },
          { name: "Worcester" },
          { name: "Yzerfontein" },
        ]
      }
    ]
  },
  {
    name: "Namibia",
    provinces: [
      {
        name: "Erongo",
        towns: [
          { name: "Arandis" },
          { name: "Henties Bay" },
          { name: "Karibib" },
          { name: "Omaruru" },
          { name: "Swakopmund" },
          { name: "Usakos" },
          { name: "Walvis Bay" },
        ]
      },
      {
        name: "Hardap",
        towns: [
          { name: "Aranos" },
          { name: "Mariental" },
          { name: "Rehoboth" },
        ]
      },
      {
        name: "Karas",
        towns: [
          { name: "Karasburg	" },
          { name: "Keetmanshoop" },
          { name: "Lüderitz" },
          { name: "Oranjemund" },
        ]
      },
      {
        name: "Kavango East",
        towns: [
          { name: "Rundu" }
        ]
      },
      {
        name: "Kavango West",
        towns: [
          { name: "Nkurenkuru" }
        ]
      },
      {
        name: "Khomas",
        towns: [
          { name: "Windhoek" }
        ]
      },
      {
        name: "Kunene",
        towns: [
          { name: "Khorixas" },
          { name: "Opuwo" },
          { name: "Outjo" }
        ]
      },
      {
        name: "Ohangwena",
        towns: [
          { name: "Eenhana" },
          { name: "Helao Nafidi" }
        ]
      },
      {
        name: "Omaheke",
        towns: [
          { name: "Gobabis" }
        ]
      },
      {
        name: "Omusati",
        towns: [
          { name: "Okahao" },
          { name: "Oshikuku" },
          { name: "Outapi" },
          { name: "Ruacana" },
        ]
      },
      {
        name: "Oshana",
        towns: [
          { name: "Ondangwa" },
          { name: "Ongwediva" },
          { name: "Oshakati" },
        ]
      },
      {
        name: "Oshikoto",
        towns: [
          { name: "Omuthiya" },
          { name: "Oniipa" },
          { name: "Tsumeb" },
        ]
      },
      {
        name: "Otjozondjupa",
        towns: [
          { name: "Grootfontein" },
          { name: "Okahandja" },
          { name: "Okakarara" },
          { name: "Otavi" },
          { name: "Otjiwarongo" },
        ]
      },
      {
        name: "Zambezi",
        towns: [
          { name: "Katima Mulilo" }
        ]
      },
    ]
  },
  {
    name: "Botswana",
    provinces: [
      {
        name: "Central District",
        towns: [
          { name: "Bobonong" },
          { name: "Borolong" },
          { name: "Chadibe" },
          { name: "Dukwi" },
          { name: "Gweta" },
          { name: "Lecheng" },
          { name: "Lerala" },
          { name: "Letlhakane" },
          { name: "Mahalapye" },
          { name: "Maitengwe" },
          { name: "Mandunyane" },
          { name: "Marobela" },
          { name: "Mathangwane" },
          { name: "Maunatlala" },
          { name: "Mmadinare" },
          { name: "Moiyabana" },
          { name: "Mookane" },
          { name: "Mopipi" },
          { name: "Nata" },
          { name: "Nkange" },
          { name: "Orapa" },
          { name: "Palapye" },
          { name: "Rakops" },
          { name: "Ramokgonami" },
          { name: "Sebina" },
          { name: "Sefophe" },
          { name: "Selibe Phikwe" },
          { name: "Serowe" },
          { name: "Shashe-Mooke" },
          { name: "Shoshong" },
          { name: "Thabala" },
          { name: "Tobane" },
          { name: "Tonota" },
          { name: "Tsetsebjwe" },
          { name: "Tumasera/Seleka" },
          { name: "Tutume" },
        ]
      },
      {
        name: "Ghanzi District",
        towns: [
          { name: "Ghanzi" }
        ]
      },
      {
        name: "Kgalagadi District",
        towns: [
          { name: "Hukuntsi" },
          { name: "Kang" },
          { name: "Tshabong" },
          { name: "Werda" }
        ]
      },
      {
        name: "Kgatleng District",
        towns: [
          { name: "Bokaa" },
          { name: "Malolwane" },
          { name: "Mochudi" },
          { name: "Oodi" },
        ]
      },
      {
        name: "Kweneng District",
        towns: [
          { name: "Gabane" },
          { name: "Khudumelapye" },
          { name: "Kopong" },
          { name: "Kumakwane" },
          { name: "Lentsweletau" },
          { name: "Letlhakeng" },
          { name: "Metsimotlhaba" },
          { name: "Mmankgodi" },
          { name: "Mmopane" },
          { name: "Mogoditshane" },
          { name: "Molepolole" },
          { name: "Thamaga" },
        ]
      },
      {
        name: "North-East District",
        towns: [
          { name: "Francistown" },
          { name: "Masunga" },
          { name: "Tati Siding" },
        ]
      },
      {
        name: "North-West District",
        towns: [
          { name: "Etsha" },
          { name: "Gumare" },
          { name: "Kasane" },
          { name: "Maun" },
          { name: "Nokaneng" },
          { name: "Sehithwa" },
          { name: "Seronga" },
          { name: "Shakawe" },
          { name: "Shorobe" },
          { name: "Toteng" },
        ]
      },
      {
        name: "South-East District",
        towns: [
          { name: "Gaborone" },
          { name: "Lobatse" },
          { name: "Otse" },
          { name: "Ramotswa" },
          { name: "Tlokweng" },
        ]
      },
      {
        name: "Southern District",
        towns: [
          { name: "Jwaneng" },
          { name: "Kanye" },
          { name: "Lotlhakane" },
          { name: "Manyana" },
          { name: "Mmathethe" },
          { name: "Molapowabojang" },
          { name: "Moshupa" },
          { name: "Ranaka" },
        ]
      }
    ]
  }
];

//export getCountries;