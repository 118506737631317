
import { Component, Prop, Vue } from "vue-property-decorator";

export type TagColour = "white" | "pink" | "blue" | "yellow" | "orange" | "green";

@Component
export default class EarTag extends Vue {
  @Prop() private tagColour?: TagColour;

  get tagColourHex() {
    switch (this.tagColour) {
      case "white":
        return "#F2F2F2"; //made colour a bit darker otherwise it doesn't look good with the other white used for lighting
      case "pink":
        return "#FFC0CB";
      case "blue":
        return "#00B9FF";
      case "yellow":
        return "#F3FF00";
      case "orange":
        return "#FF6800";
      case "green":
        return "#00EE16";
      case undefined:
        return "#00EE16";
      default:
        return "#00EE16";
    }
  }
}
