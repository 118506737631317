
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import AnimalEventHistory from "@/components/AnimalEventHistory.vue";
import DimssaButton, { ButtonState } from "@/components/shared/dimssa-button.vue";
import EarTag from "@/components/shared/EarTag.vue";
import * as Models from "@gigalot/data-models";
import { capitalize } from "@/helpers";
import { getTagColour, tagId, visualNumber } from "@/helpers/visual-tag";
import moment from "moment";
import { listAnimal } from "@/helpers/graphql-list-items";

@Component({ components: { DimssaButton, EarTag, AnimalEventHistory } })
export default class AnimalHistory extends Vue {
  selectedAnimalListItem: any = "";
  selectedAnimal: Models.Animal | "" = "";
  showSelectedAnimal: boolean = false;
  getAnimalErrorMessage: string = "";

  tagIdFunc = tagId;
  moment = moment;

  async getAnimalFromServer(): Promise<Models.Animal> {
    let json = await this.$store.dispatch(
      "graphQl",
      {
        gql: Models.gql.queries.getAnimal,
        variables: {
          guid: this.$store.state.user.location.guid,
          itemGuid: this.selectedAnimalListItem.guid,
        },
      },
      { root: true }
    );
    console.log("graphQL:");
    console.dir(json);

    return json.data.getAnimal as Models.Animal;
  }

  @Watch("selectedAnimalListItem")
  async onSelectedAnimalListItemChanged(selectedAnimalListItem: any) {
    console.log("onSelectedAnimalListItemChanged");
    console.dir(selectedAnimalListItem);

    if (selectedAnimalListItem) {
      try {
        this.selectedAnimal = "";
        this.getAnimalErrorMessage = "";
        this.selectedAnimal = await this.getAnimalFromServer();
      } catch (err) {
        console.error("error getting animal from server:");
        console.error(err);
        this.getAnimalErrorMessage = (err as any)?.toString() ?? "no error message found";
      }
    } else this.selectedAnimal = "";
  }

  @Watch("selectedAnimal")
  onSelectedAnimalChanged(selectedAnimal: any) {
    this.showSelectedAnimal = selectedAnimal ? true : false;
  }

  refreshAnimalListItemsButtonState: ButtonState = "ready";
  refreshAnimalListItemsErrorMessage: string = "";
  async refreshAnimalListItems() {
    try {
      this.refreshAnimalListItemsButtonState = "busy";
      this.refreshAnimalListItemsErrorMessage = "";

      this.animalListItems = await listAnimal();

      this.refreshAnimalListItemsButtonState = "success";
    } catch (err) {
      this.animalListItems = [];
      console.error("refreshAnimalListItems() error:");
      console.error(err);
      this.refreshAnimalListItemsErrorMessage = (err as any)?.toString() ?? "no error message found";
      this.refreshAnimalListItemsButtonState = "error";
    }
  }

  animalListItems: {
    typename: string;
    guid: string;
    sgtin?: string;
    owner?: string;
    breed?: string;
    gender?: string;
    tagId?: string;
  }[] = [];

  async created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$router.go(-1);
      },
    });
    this.refreshAnimalListItems();
  }

  eventType(event: Models.AnimalEvent) {
    if (event.type === "scanning_roll-call") return "Count";
    if (event.type === "scanning_group-weigh") return "Group Weigh";
    if (event.type === "scanning_dispatch-to-kraal") return "Count";
    if (event.type === "scanning_dispatch-to-abattoir-gantry") return "Dispatch to Abattoir Gantry";

    if (event.type === "processing_new") return "New Intake";
    if (event.type === "processing_reprocess") return "Reprocess";
    if (event.type === "processing_reweigh") return "Reweigh";
    if (event.type === "processing_reimplant") return "Reimplant";
    if (event.type === "processing_dispatch-to-abattoir-processed") return "Dispatch to Abattoir";
    if (event.type === "processing_dispatch-to-site-processed") return "Dispatch to Site";

    if (event.type === "hospital") return "Hospital";

    return event.type;
  }

  get tagColour() {
    if (!this.selectedAnimal) throw Error("No selected animal");
    return getTagColour(this.selectedAnimal.sgtin);
  }

  get visualTag() {
    return this.selectedAnimal ? visualNumber(this.selectedAnimal.sgtin) : "";
  }

  get firstProcessedTime() {
    if (!this.selectedAnimal) throw Error("No selected animal");
    return this.selectedAnimal.events[this.selectedAnimal.events.length - 1].time as number;
  }

  get tagId() {
    if (!this.selectedAnimal) throw Error("No selected animal");
    return tagId(this.selectedAnimal.sgtin);
  }

  //TODO: this is misleading since some animals (probably incomplete retagged) don't have "new" as a their first event
  get standingDays() {
    return moment().diff(moment(this.firstProcessedTime), "days");
  }

  get gender() {
    if (this.selectedAnimal) return capitalize(this.selectedAnimal?.gender ?? "");
    else return "";
  }

  gainBetweenEvents(event: Models.AnimalEvent, index: number) {
    if (!this.selectedAnimal) throw Error("No selected animal");
    if (index >= this.selectedAnimal.events.length) return "N/A";
    let previousEvent = this.selectedAnimal.events[index + 1];
    if (event.mass === undefined || previousEvent.mass === undefined) return "N/A";
    return event.mass - previousEvent.mass;
  }

  daysBetweenEvents(event: Models.AnimalEvent, index: number) {
    if (!this.selectedAnimal) throw Error("No selected animal");
    if (index >= this.selectedAnimal.events.length) return "N/A";
    let previousEvent = this.selectedAnimal.events[index + 1];
    return moment(event.time).diff(moment(previousEvent.time), "days");
  }
}
