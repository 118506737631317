
import { Component, Vue, Watch } from "vue-property-decorator";
import DimssaButton, { ButtonState } from "@/components/shared/dimssa-button.vue";
import EarTag from "@/components/shared/EarTag.vue";
import AnimalEventHistory from "@/components/AnimalEventHistory.vue";
import lodash from "lodash";
import * as Models from "@gigalot/data-models";
import { getTagColour, tagId, visualNumber } from "@/helpers/visual-tag";
import { changeTrackingComponentFragment } from "@/helpers/gql";
import { getItemFromServer } from "@/helpers/get-item-from-server";
import { listAilment, listTreatment } from "@/helpers/graphql-list-items";
import { decimal } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { listAnimal } from "@/helpers/graphql-list-items";

@Component({
  components: {
    AnimalEventHistory,
    DimssaButton,
    EarTag,
  },
  mixins: [validationMixin],
  validations: {
    temperature: { decimal },
    mass: { decimal, positive: (val) => val > 0 },
  },
})
export default class Hospital extends Vue {
  selectedSourceKraalId: any = "";
  selectedDestinationKraalId: any = "";

  tagColour: string = "green";

  animalListItemSearch: string = "";

  @Watch("animalListItemSearch")
  onAnimalListItemSearchChanged(val: any) {
    console.log("onAnimalListItemSearchChanged", this.animalListItemSearch);

    const NUM_CHARS = 3;
    if (this.animalListItemSearch?.length == NUM_CHARS) {
      this.refreshAnimalListItems();
    } else if (this.animalListItemSearch?.length < NUM_CHARS) {
      this.refreshAnimalListItemsButtonState = "disabled";
      this.animalListItems = [];
    }
  }

  changed: boolean = false;

  @Watch("changed")
  onChangeChanged(val: any) {
    this.$store.commit("navFuncs", { save: this.save, back: this.back });
  }

  async created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$store.commit("updateUserLocationField", { path: "officeHospitalResult", value: {} });
        this.$router.push({ path: "list/office-hospital-results" });
      },
    });
    if (!this.officeHospitalResult.morbidity) {
      this.$store.commit("updateUserLocationField", { path: "officeHospitalResult.morbidity", value: new Models.Morbidity() });
    }
    // this.ailments = (await this.$store.dispatch("data/get", { objectStore: "Ailment" })) as Models.Ailment[];
    // this.treatments = (await this.$store.dispatch("data/get", { objectStore: "Treatment" })) as Models.Treatment[];
    //add selected treatment to list if not found in treatments (otherwise selected treatment might disappear)
    //if (this.officeHospitalResult.morbidity?.appliedTreatment.length && this.officeHospitalResult.morbidity.appliedTreatment[0].treatment?.description) {
    //let treatment = this.officeHospitalResult.morbidity.appliedTreatment[0].treatment;
    //if (!this.treatments.find(t => t.description === treatment.description)) this.treatments.push(treatment);
    //}

    if (this.officeHospitalResult.morbidity?.appliedTreatment.length) {
      this.selectedTreatment = this.officeHospitalResult.morbidity.appliedTreatment[0].treatment;
    }
    this.selectedAilments = this.officeHospitalResult.morbidity?.ailments || [];
    if (this.officeHospitalResult.morbidity?.time !== undefined && this.officeHospitalResult.morbidity?.time !== null) {
      this.dateString = this.moment(this.officeHospitalResult.morbidity?.time).format("YYYY-MM-DD");
      this.timeString = this.moment(this.officeHospitalResult.morbidity?.time).format("HH:mm");
    }
    this.massChange(this.officeHospitalResult.morbidity?.mass);
    this.temperatureChange(this.officeHospitalResult.morbidity?.temperature);
    if (this.officeHospitalResult.morbidity?.notes.length) this.notes = this.officeHospitalResult.morbidity.notes[0];

    // this.animals = await this.$store.dispatch("data/get", { objectStore: "Animal" });
    // this.animals = this.animals.map((a: any) => Object.assign(a, { visualSgtin: sgtinH.visual(a.sgtin) }));
    // if (this.officeHospitalResult.morbidity?.sgtin && !this.animals.find(a => a.sgtin === this.officeHospitalResult.morbidity?.sgtin))
    //   this.animals = [
    //     {
    //       sgtin: this.officeHospitalResult.morbidity.sgtin,
    //       visualSgtin: sgtinH.visual(this.officeHospitalResult.morbidity.sgtin),
    //       typename: "Animal",
    //       guid: `${Date.now()}-${uuid()}`,
    //       events: []
    //     },
    //     ...this.animals
    //   ];

    // this.selectedAnimal = this.officeHospitalResult.morbidity?.sgtin
    //   ? this.animals.find(a => a.sgtin === this.officeHospitalResult.morbidity?.sgtin)
    //   : undefined;

    this.selectedSourceKraalId = this.officeHospitalResult.morbidity?.sourceKraalId ?? "";
    this.selectedDestinationKraalId = this.officeHospitalResult.morbidity?.destinationKraalId ?? "";

    this.determineAppliedDoses();

    this.determineTagColour();

    this.getKraals();

    if (this.selectedTreatment) {
      this.selectedTreatmentListItem = { guid: this.selectedTreatment.guid, text: this.selectedTreatment.description ?? "" };
      this.getSelectedTreatmentButtonState = "success";
    }
    this.getTreatmentListItems();

    for (const selectedAilment of this.selectedAilments) {
      if (selectedAilment) {
        //this.selectedAilmentListItem = { guid: selectedAilment.guid, text: selectedAilment.ailment ?? "" };
        this.selectedAilmentsListItems.push({ guid: selectedAilment.guid, text: selectedAilment.ailment ?? "" });
        this.getSelectedAilmentsButtonState = "success";
      }
    }
    this.getAilmentListItems();

    //await this.refreshAnimalListItems();

    //this.selectedAnimal = this.officeHospitalResult.morbidity?.sgtin ? this.animalListItems.find(a => a.sgtin === this.officeHospitalResult.morbidity?.sgtin) : undefined;
    this.selectedAnimalListItem = this.officeHospitalResult.morbidity?.sgtin
      ? this.animalListItems.find((a) => a.sgtin === this.officeHospitalResult.morbidity?.sgtin)
      : "";
    if (!this.selectedAnimalListItem) this.selectedAnimalListItem = "";
  }

  determineTagColour() {
    this.tagColour = this.officeHospitalResult?.morbidity?.sgtin ? getTagColour(this.officeHospitalResult?.morbidity?.sgtin as string)?.name : "green";
  }

  @Watch("selectedSourceKraalId")
  sourceKraalIdChanged(selectedSourceKraalId: any) {
    this.$store.commit("updateUserLocationField", { path: "officeHospitalResult.morbidity.sourceKraalId", value: selectedSourceKraalId });
  }

  @Watch("selectedDestinationKraalId")
  destinationKraalIdChanged(selectedDestinationKraalId: any) {
    this.$store.commit("updateUserLocationField", { path: "officeHospitalResult.morbidity.destinationKraalId", value: selectedDestinationKraalId });
  }

  dateString: string = "";
  dateMenu: boolean = false;

  timeString: string = "";
  timeMenu: boolean = false;

  setDateTime() {
    if (!this.dateString) return;
    if (!this.timeString) return;
    const s = `${this.dateString} ${this.timeString}`;
    console.log(`setting date time ${s} (${this.moment(s).valueOf()})`);
    this.$store.commit("updateUserLocationField", {
      path: "officeHospitalResult.morbidity.time",
      value: this.moment(s).valueOf(),
    });
  }

  get officeHospitalResult(): Models.OfficeHospitalResult {
    return this.$store.getters["storage"]().officeHospitalResult;
  }

  get moment() {
    return this.$store.state.moment;
  }

  rules = {
    validNumber: (value: string) => /^[0-9]*\.{0,1}[0-9]*$/.test(value) || "Invalid input.",
  };

  mass: string = "";
  massChange(mass: any) {
    this.mass = mass ?? "";
    //must check for true explicitly due to how rules works with bools and strings
    if (this.rules.validNumber(mass) !== true) {
      this.$store.commit("updateUserLocationField", { path: "officeHospitalResult.morbidity.mass", value: undefined });
      return;
    }
    this.$store.commit("updateUserLocationField", {
      path: "officeHospitalResult.morbidity.mass",
      value: isNaN(parseFloat(mass)) ? undefined : parseFloat(mass),
    });
    this.determineAppliedDoses();
  }

  temperature: string = "";
  temperatureChange(temperature: any) {
    this.temperature = temperature ?? "";
    //must check for true explicitly due to how rules works with bools and strings
    if (this.rules.validNumber(temperature) !== true) {
      this.$store.commit("updateUserLocationField", { path: "officeHospitalResult.morbidity.temperature", value: undefined });
      return;
    }
    this.$store.commit("updateUserLocationField", {
      path: "officeHospitalResult.morbidity.temperature",
      value: isNaN(parseFloat(temperature)) ? undefined : parseFloat(temperature),
    });
  }

  notes = "";
  notesChange(notes: any) {
    this.notes = notes;
    this.$store.commit("updateUserLocationField", { path: "officeHospitalResult.morbidity.notes", value: [notes] });
  }

  doses: Models.Dose[] = [];

  get doseTypes(): { [doseDescription: string]: "cc-mass-dependent" | "constant" } {
    let ret: { [doseDescription: string]: "cc-mass-dependent" | "constant" } = {};
    for (let dose of this.doses) {
      if (dose.item && dose.type) ret[dose.item.description] = dose.type;
      else throw Error("TODO: fix error handling for when dose not found");
    }
    return ret;
  }

  appliedDoses: Models.AppliedDose[] = [];

  get appliedDosesMassDependent() {
    return this.appliedDoses.filter((ad) => this.doseTypes[ad.item.description] === "cc-mass-dependent");
  }

  get appliedDosesMassConstant() {
    return this.appliedDoses.filter((ad) => this.doseTypes[ad.item.description] === "constant");
  }

  determineAppliedDoses() {
    //console.log("determineAppliedDoses");

    if (!this.officeHospitalResult.morbidity?.appliedTreatment.length) return;
    let appliedTreatment = this.officeHospitalResult.morbidity.appliedTreatment[0];
    let treatment = appliedTreatment.treatment;
    let mass = parseFloat(this.mass);
    if (!treatment) {
      Vue.set(this.officeHospitalResult.morbidity, "appliedTreatment", []);
      this.$store.commit("updateUserLocationField", {
        path: "officeHospitalResult.morbidity.appliedTreatment",
        value: [],
      });
      return;
    }

    appliedTreatment.appliedDoses = [];

    for (let dose of treatment.doses) {
      let appliedDose = new Models.AppliedDose();
      if (!dose.item) throw Error(`${treatment.description} has a dose that has no dispensary item`);
      appliedDose.item = dose.item;
      appliedDose.unit = dose.unit;
      if (!dose.amount) {
        throw Error(`${treatment.description} has a dose that does not have amount set`);
      }
      if (dose.type === "cc-mass-dependent") {
        if (mass !== undefined && !isNaN(mass)) {
          if (!dose.kgPerDose) throw Error(`${treatment.description} has a dose that is cc-mass-dependent but does not have kgPerDose set`);
          appliedDose.actualDose = Math.ceil((mass / dose.kgPerDose) * dose.amount);
          appliedTreatment.appliedDoses.push(appliedDose);
        }
      } else {
        appliedDose.actualDose = dose.amount;
        appliedTreatment.appliedDoses.push(appliedDose);
      }
    }

    Vue.set(this.officeHospitalResult.morbidity, "appliedTreatment", [appliedTreatment]);
    this.$store.commit("updateUserLocationField", {
      path: "officeHospitalResult.morbidity.appliedTreatment",
      value: [appliedTreatment],
    });

    if (!this.officeHospitalResult.morbidity?.appliedTreatment.length) {
      this.doses = [];
      this.appliedDoses = [];
    } else {
      this.doses = this.officeHospitalResult.morbidity.appliedTreatment[0].treatment.doses;
      this.appliedDoses = this.officeHospitalResult.morbidity.appliedTreatment[0].appliedDoses;
    }
  }

  save() {
    //TODO: data validation before saving
    if (!this.officeHospitalResult.morbidity?.sgtin) {
      this.$store.commit("popup/displayOk", `Can not save. No Tag ID selected.`);
      return;
    }

    if (this.dateString && !this.timeString) {
      this.$store.commit("popup/displayOk", `Can not save. If a date is set then a time must be set too.`);
      return;
    }

    if (this.timeString && !this.dateString) {
      this.$store.commit("popup/displayOk", `Can not save. If a time is set then a date must be set too.`);
      return;
    }

    //TODO: Check if treatment has any mass-dependent doses. If it does then a mass has to be entered

    //TODO: popup and block saving if another mortality with the same sgtin (different guid) is found

    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure that you want to save?",
      yesAction: async () => {
        try {
          this.$store.commit("popup/displayWait", "Saving...");
          //console.log(this.$store.getters["storage"]().mortality);
          let officeHospitalResultClone: Models.OfficeHospitalResult = lodash.cloneDeep(this.$store.getters["storage"]().officeHospitalResult);
          officeHospitalResultClone.metadata = this.$store.getters["user/getUpstreamMetadata"]();
          let json = await this.$store.dispatch(
            "graphQl",
            {
              gql: `mutation officeHospitalResult($guid: String!, $officeHospitalResult: OfficeHospitalResultInput!) {
                officeHospitalResult(guid: $guid, officeHospitalResult: $officeHospitalResult) {
                  ...changeTrackingFields
                }
              }${changeTrackingComponentFragment}`,
              variables: {
                guid: this.$store.state.user.location.guid,
                officeHospitalResult: officeHospitalResultClone as Models.OfficeHospitalResult,
              },
              timeout: 10 * 1000
            },
            { root: true }
          );
          console.log("graphQL: " + JSON.stringify(json));
          let officeHospitalResult = this.$store.getters["storage"]().officeHospitalResult as Models.OfficeHospitalResult;
          officeHospitalResult.changeTracking = json.data.officeHospitalResult;
          //await this.$store.dispatch("data/set", { objectStore: "OfficeHospitalResult", items: [lodash.cloneDeep(officeHospitalResult)] });
          this.$router.push({ path: "list/office-hospital-results" });
          this.$store.commit("popup/hide");
        } catch (err) {
          console.log(err);
          this.$store.commit("popup/hide");
          this.$store.commit("popup/displayOk", { message: `Error: ${err}` });
        }
      },
    });
  }

  back() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure? Unsaved work will be lost.",
      yesAction: () => {
        this.$store.commit("updateUserLocationField", { path: "officeHospitalResult", value: {} });
        this.$router.push({ path: "list/office-hospital-results" });
      },
    });
  }

  tagIdFunc(sgtin: string) {
    return tagId(sgtin);
  }

  ////////
  // Ailments

  ailmentListItems: { guid: string; text: string }[] = [];
  selectedAilmentsListItems: ({ guid: string; text: string } | "")[] = [];
  getAilmentListItemsButtonState: ButtonState = "ready";
  getSelectedAilmentsButtonState: ButtonState = "ready";

  get modelSelectedAilmentsListItems(): ({ guid: string; text: string } | "")[] {
    return this.selectedAilmentsListItems;
  }

  set modelSelectedAilmentsListItems(selectedAilmentsListItems: ({ guid: string; text: string } | "")[]) {
    if (selectedAilmentsListItems === undefined) selectedAilmentsListItems = [];

    this.selectedAilmentsListItems = selectedAilmentsListItems;
    this.getAilmentsFromServer();
  }

  async getAilmentsFromServer() {
    if (!this.selectedAilmentsListItems.length) return;
    try {
      this.getSelectedAilmentsButtonState = "busy";
      const ailments = [];
      for (const selectedAilmentListItem of this.selectedAilmentsListItems)
        if (selectedAilmentListItem) ailments.push(await getItemFromServer("Ailment", selectedAilmentListItem.guid));

      this.ailmentChange(ailments);
      this.getSelectedAilmentsButtonState = "success";
    } catch (err) {
      this.getSelectedAilmentsButtonState = "error";
      console.error(err);
      this.ailmentChange([]);
    }
  }

  async getAilmentListItems() {
    try {
      this.getAilmentListItemsButtonState = "busy";
      this.ailmentListItems = [];
      let items = await listAilment(true);
      this.ailmentListItems = items.map((i: any) => ({ guid: i.guid, text: i.ailment }));
      this.getAilmentListItemsButtonState = "success";
    } catch (err) {
      console.error(err);
      this.getAilmentListItemsButtonState = "error";
    }

    //If selected is not found in list then add it
    for (const selectedAilment of this.selectedAilments) {
      if (selectedAilment && !this.ailmentListItems.find((t) => t.guid === (selectedAilment as Models.Ailment).guid)) {
        const a = selectedAilment as Models.Ailment;
        this.ailmentListItems.push({ guid: a.guid, text: a.ailment ?? "" });
      }
    }

    this.ailmentListItems.sort((i1, i2) => (i1.text < i2.text ? -1 : 1));
  }

  //ailments: Models.Ailment[] = [];
  selectedAilments: Models.Ailment[] = [];
  ailmentChange(ailments: any) {
    //console.log(ailments);
    this.$store.commit("updateUserLocationField", { path: "officeHospitalResult.morbidity.ailments", value: ailments });
  }

  // Ailments
  ////////
  // Animals

  //selectedAnimal: any = "";
  //animals: any[] = [];
  //animals: (Models.Animal & { visualSgtin: string })[] = [];

  selectedAnimalListItem: any = "";
  selectedAnimal: Models.Animal | "" = "";
  refreshAnimalListItemsButtonState: ButtonState = "disabled";
  refreshAnimalListItemsErrorMessage: string = "";
  getAnimalErrorMessage: string = "";

  animalListItems: {
    typename: string;
    guid: string;
    sgtin?: string;
    owner?: string;
    breed?: string;
    gender?: string;
    tagId?: string;
  }[] = [];

  @Watch("selectedAnimal")
  selectedAnimalChanged(selectedAnimal: any) {
    //console.log("selectedAnimal: " + JSON.stringify(selectedAnimal));
    let updateRequired = selectedAnimal?.sgtin !== this.officeHospitalResult.morbidity?.sgtin;
    this.$store.commit("updateUserLocationField", { path: "officeHospitalResult.morbidity.sgtin", value: selectedAnimal ? selectedAnimal.sgtin : undefined });
    if (updateRequired) {
      this.$store.commit("updateUserLocationField", {
        path: "officeHospitalResult.customFeeder",
        value: selectedAnimal ? selectedAnimal.customFeeder : undefined,
      });
      this.$store.commit("updateUserLocationField", { path: "officeHospitalResult.breed", value: selectedAnimal ? selectedAnimal.breed : undefined });
      this.$store.commit("updateUserLocationField", { path: "officeHospitalResult.gender", value: selectedAnimal ? selectedAnimal.gender : undefined });
    }
    this.determineTagColour();
  }

  @Watch("selectedAnimalListItem")
  async onSelectedAnimalListItemChanged(selectedAnimalListItem: any) {
    console.log("onSelectedAnimalListItemChanged");
    console.dir(selectedAnimalListItem);

    if (selectedAnimalListItem) {
      try {
        this.selectedAnimal = "";
        this.getAnimalErrorMessage = "";
        this.selectedAnimal = await this.getAnimalFromServer();
      } catch (err) {
        console.error("error getting animal from server:");
        console.error(err);
        this.getAnimalErrorMessage = (err as any)?.toString() ?? "no error message found";
      }
    } else this.selectedAnimal = "";
  }

  async getAnimalFromServer(): Promise<Models.Animal> {
    let json = await this.$store.dispatch(
      "graphQl",
      {
        gql: Models.gql.queries.getAnimal,
        variables: {
          guid: this.$store.state.user.location.guid,
          itemGuid: this.selectedAnimalListItem.guid,
        },
      },
      { root: true }
    );
    console.log("graphQL:");
    console.dir(json);

    return json.data.getAnimal as Models.Animal;
  }

  async refreshAnimalListItems() {
    try {
      this.refreshAnimalListItemsButtonState = "busy";
      this.refreshAnimalListItemsErrorMessage = "";

      this.animalListItems = await listAnimal(this.animalListItemSearch);

      this.refreshAnimalListItemsButtonState = "success";
    } catch (err) {
      this.animalListItems = [];
      console.error("refreshAnimalListItems() error:");
      console.error(err);
      this.refreshAnimalListItemsErrorMessage = (err as any)?.toString() ?? "no error message found";
      this.refreshAnimalListItemsButtonState = "error";
    }
  }

  // Animals
  ////////
  // Kraals

  kraalIds: string[] = [];
  getKraalsButtonState: ButtonState = "ready";

  async getKraals() {
    try {
      this.getKraalsButtonState = "busy";
      let json = await this.$store.dispatch(
        "graphQl",
        {
          gql: `query Kraals($guid: String!) {
          Kraals(guid: $guid) {
            kraalId
          }
        }`,
          variables: {
            guid: this.$store.state.user.location.guid,
          },
        },
        { root: true }
      );
      console.log("graphQL:");
      console.dir(json);
      this.kraalIds = json.data.Kraals.map((k: any) => k.kraalId);
      this.getKraalsButtonState = "success";
    } catch (err) {
      this.getKraalsButtonState = "error";
    }
  }

  // Kraals
  ////////
  // Treatments

  treatmentListItems: { guid: string; text: string }[] = [];
  selectedTreatmentListItem: { guid: string; text: string } | "" = "";
  getTreatmentListItemsButtonState: ButtonState = "ready";
  getSelectedTreatmentButtonState: ButtonState = "ready";

  get modelSelectedTreatmentListItem(): { guid: string; text: string } | "" {
    return this.selectedTreatmentListItem;
  }

  set modelSelectedTreatmentListItem(selectedTreatmentListItem: { guid: string; text: string } | "") {
    if (selectedTreatmentListItem === undefined) selectedTreatmentListItem = "";

    if (
      this.selectedTreatmentListItem &&
      selectedTreatmentListItem &&
      selectedTreatmentListItem.guid &&
      this.selectedTreatmentListItem.guid === selectedTreatmentListItem.guid
    )
      return; // if same already selected then do nothing

    this.selectedTreatmentListItem = selectedTreatmentListItem;
    this.getTreatmentFromServer();
  }

  async getTreatmentFromServer() {
    if (!this.selectedTreatmentListItem || !this.selectedTreatmentListItem.guid) return;
    try {
      this.getSelectedTreatmentButtonState = "busy";
      const treatment = await getItemFromServer("Treatment", this.selectedTreatmentListItem.guid);
      this.treatmentChange(treatment);
      this.getSelectedTreatmentButtonState = "success";
    } catch (err) {
      this.getSelectedTreatmentButtonState = "error";
      console.error(err);
      this.treatmentChange(undefined);
    }
  }

  async getTreatmentListItems() {
    try {
      this.getTreatmentListItemsButtonState = "busy";
      this.treatmentListItems = [];
      let items = await listTreatment(true);
      this.treatmentListItems = items.map((i: any) => ({ guid: i.guid, text: i.description }));
      this.getTreatmentListItemsButtonState = "success";
    } catch (err) {
      console.error(err);
      this.getTreatmentListItemsButtonState = "error";
    }

    //If selected is not found in list then add it
    if (this.selectedTreatment && !this.treatmentListItems.find((t) => t.guid === (this.selectedTreatment as Models.Treatment).guid)) {
      const t = this.selectedTreatment as Models.Treatment;
      this.treatmentListItems.push({ guid: t.guid, text: t.description ?? "" });
    }

    this.treatmentListItems.sort((i1, i2) => (i1.text < i2.text ? -1 : 1));
  }

  //treatments: Models.Treatment[] = [];
  selectedTreatment: Models.Treatment | "" = "";
  treatmentChange(treatment?: Models.Treatment) {
    //console.log("treatmentChange: " + treatment);

    this.selectedTreatment = treatment || "";

    let appliedTreatments: Models.AppliedTreatment[] = [];
    if (treatment) {
      appliedTreatments.push(new Models.AppliedTreatment());
      appliedTreatments[0].treatment = treatment;
    }
    if (this.officeHospitalResult.morbidity) Vue.set(this.officeHospitalResult.morbidity, "appliedTreatment", appliedTreatments);
    this.$store.commit("updateUserLocationField", { path: "officeHospitalResult.morbidity.appliedTreatment", value: appliedTreatments });
    this.determineAppliedDoses();
  }

  // Treatments
  ////////
}
