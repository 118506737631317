
import { Component, Vue } from "vue-property-decorator";
import DimssaButton from "@/components/shared/dimssa-button.vue";

@Component({
  components: {
    DimssaButton,
  },
})
export default class Material extends Vue {}
