
import { Component, Vue, Watch } from "vue-property-decorator";
import DimssaButton from "@/components/shared/dimssa-button.vue";
import lodash from "lodash";
import * as Models from "@gigalot/data-models";
import { mapFields } from "@/store";
import { changeTrackingComponentFragment } from "@/helpers/gql";

@Component({
  components: {
    DimssaButton,
  },
  computed: {
    ...mapFields(["ailmentCategory.category", "ailmentCategory.active"]),
  },
})
export default class AilmentCategory extends Vue {
  changed: boolean = false;

  @Watch("changed")
  onChangeChanged(val: any) {
    this.$store.commit("navFuncs", { save: this.save, back: this.back });
  }
  created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$store.commit("updateUserLocationField", { path: "ailmentCategory", value: {} });
        this.$router.push({ path: "list/ailment-categories" });
      },
    });
  }

  save() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure that you want to save?",
      yesAction: async () => {
        try {
          //TODO: popup and block saving if another ailmentCategory with the same category (different guid) is found
          this.$store.commit("popup/displayWait", "Saving...");
          console.log(this.$store.getters["storage"]().ailmentCategory);
          let ailmentCategoryClone = lodash.cloneDeep(this.$store.getters["storage"]().ailmentCategory);
          ailmentCategoryClone.metadata = this.$store.getters["user/getUpstreamMetadata"]();
          let json = await this.$store.dispatch(
            "graphQl",
            {
              gql: `mutation ailmentCategory($guid: String!, $ailmentCategory: AilmentCategoryInput!) {
                ailmentCategory(guid: $guid, ailmentCategory: $ailmentCategory) {
                  ...changeTrackingFields
                }
              }${changeTrackingComponentFragment}`,
              variables: {
                guid: this.$store.state.user.location.guid,
                ailmentCategory: ailmentCategoryClone as Models.AilmentCategory,
              },
              timeout: 10 * 1000
            },
            { root: true }
          );
          console.log("graphQL: " + JSON.stringify(json));
          let ailmentCategory = this.$store.getters["storage"]().ailmentCategory as Models.AilmentCategory;
          ailmentCategory.changeTracking = json.data.ailmentCategory;
          //await this.$store.dispatch("data/set", { objectStore: "AilmentCategory", items: [lodash.cloneDeep(ailmentCategory)] });

          // let ailments = (await this.$store.dispatch("data/get", { objectStore: "Ailment" })) as Models.Ailment[];
          // for (let ailment of ailments) {
          //   if (ailment.category && ailment.category.guid === ailmentCategory.guid) {
          //     ailment.category = lodash.cloneDeep(ailmentCategory);
          //     await this.$store.dispatch("data/set", { objectStore: "Ailment", items: [lodash.cloneDeep(ailment)] });
          //   }
          // }

          this.$router.push({ path: "list/ailment-categories" });
          this.$store.commit("popup/hide");
        } catch (err) {
          this.$store.commit("popup/hide");
          console.log(err);
          this.$store.commit("popup/displayOk", { message: `Error: ${err}` });
        }
      },
    });
  }

  back() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure? Unsaved work will be lost.",
      yesAction: () => {
        this.$store.commit("updateUserLocationField", { path: "ailmentCategory", value: {} });
        this.$router.push({ path: "list/ailment-categories" });
      },
    });
  }
}
