
import { Component, Vue } from "vue-property-decorator";
import DimssaButton from "@/components/shared/dimssa-button.vue";

@Component({
  components: {
    DimssaButton,
  },
})
export default class General extends Vue {
  created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$router.push({ name: "home" });
      },
    });
  }
}
