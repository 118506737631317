
import { Component, Vue, Watch } from "vue-property-decorator";
import DimssaButton from "@/components/shared/dimssa-button.vue";
import lodash from "lodash";
import * as Models from "@gigalot/data-models";
import { changeTrackingComponentFragment } from "@/helpers/gql";

@Component({
  components: {
    DimssaButton,
  },
})
export default class DispensaryItemCost extends Vue {
  changed: boolean = false;

  @Watch("changed")
  onChangeChanged(val: any) {
    if (this.changed) this.$store.commit("navFuncs", { save: this.save, back: this.back });
    else
      this.$store.commit("navFuncs", {
        save: undefined,
        back: () => {
          this.$store.commit("updateUserLocationField", { path: "intakeBatch", value: {} });
          this.$router.push({ path: "list/dispensary-item-costs" });
        },
      });
  }

  back() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure? Unsaved work will be lost.",
      yesAction: () => {
        this.$router.push({ path: "list/dispensary-item-costs" });
      },
    });
  }

  async save() {
    let self = this;
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure that you want to save?",
      yesAction: async () => {
        try {
          this.$store.commit("popup/displayWait", "Saving...");
          if (self.costDispensaryItem) {
            await self.saveCost(self.costDispensaryItem);
            //this.$router.push({ path: "list/dispensary-item-costs" });
            this.changed = false;
          } else throw Error("no costDispensaryItem");
          this.$store.commit("popup/hide");
        } catch (err) {
          console.log(err);
          this.$store.commit("popup/hide");
          this.$store.commit("popup/displayOk", { message: `Error: ${err}` });
        }
      },
    });
  }

  async saveCost(cost: Models.CostDispensaryItem) {
    if (!cost) throw Error("saveCost cost is undefined");
    let costClone = lodash.cloneDeep(cost);
    costClone.metadata = this.$store.getters["user/getUpstreamMetadata"]();
    let json = await this.$store.dispatch(
      "graphQl",
      {
        gql: `mutation costDispensaryItem($guid: String!, $cost: CostDispensaryItemInput!) {
                costDispensaryItem(guid: $guid, cost: $cost) {
                  ...changeTrackingFields
                }
              }${changeTrackingComponentFragment}`,
        variables: { guid: this.$store.state.user.location.guid, cost: costClone },
        timeout: 10 * 1000
      },
      { root: true }
    );
    let costDispensaryItem = this.costDispensaryItem as Models.CostDispensaryItem;
    costDispensaryItem.changeTracking = json.data.costDispensaryItem;
    this.$store.commit("updateUserLocationField", { path: "costDispensaryItem", value: costDispensaryItem });
  }

  copyItem(item: any) {
    this.tempSince = item.since;
    this.tempCost = item.value;
    this.tempPer = item.per;
    this.tempUnit = item.unit;
  }

  deleteItem(item: any) {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure?",
      yesAction: () => {
        if (!this.costDispensaryItem) return;
        // const index = this.costDispensaryItem.history.indexOf(item);
        // this.costDispensaryItem.history.splice(index, 1);
        const index = this.history.indexOf(item);
        this.history.splice(index, 1);
        this.costDispensaryItem.history = this.history;
        this.costDispensaryItem = this.costDispensaryItem;
        this.changed = true;
      },
    });
  }

  async created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$store.commit("updateUserLocationField", { path: "dispensaryItem", value: {} });
        this.$store.commit("updateUserLocationField", { path: "costDispensaryItem", value: {} });
        this.$router.push({ path: "list/dispensary-item-costs" });
      },
    });
    console.log(JSON.stringify(this.dispensaryItem));

    this.tempUnit = this.dispensaryItem.unit;

    this.history = this.costDispensaryItem.history;
  }

  addCost() {
    if (this.costDispensaryItem) {
      this.$store.commit("snackbar", { visible: false });
      if (this.tempCost && this.tempCost > 0 && this.tempUnit && this.tempPer && this.tempPer > 0) {
        console.log(this.tempSince);
        //let historyItemIndex = lodash.findIndex(this.costDispensaryItem.history, { since: this.tempSince, unit: this.tempUnit });
        let historyItemIndex = lodash.findIndex(this.history, { since: this.tempSince, unit: this.tempUnit });
        if (historyItemIndex >= 0) {
          // this.costDispensaryItem.history[historyItemIndex].value = this.tempCost;
          // this.costDispensaryItem.history[historyItemIndex].per = this.tempPer;
          this.history[historyItemIndex].value = this.tempCost;
          this.history[historyItemIndex].per = this.tempPer;
          //} else this.costDispensaryItem.history.push({ value: this.tempCost, since: this.tempSince, per: this.tempPer, unit: this.tempUnit });
        } else this.history.push({ value: this.tempCost, since: this.tempSince, per: this.tempPer, unit: this.tempUnit });

        this.costDispensaryItem.history = this.history;
        this.costDispensaryItem = this.costDispensaryItem;

        this.changed = true;
      } else {
        if (!this.tempCost || this.tempCost <= 0) this.$store.commit("snackbar", { message: `Invalid Cost`, timeout: 5000 });
        if (!this.tempUnit) this.$store.commit("snackbar", { message: `Invalid Units`, timeout: 5000 });
        if (!this.tempPer || this.tempPer <= 0) this.$store.commit("snackbar", { message: `Invalid Per`, timeout: 5000 });
      }
      this.$forceUpdate();
    } else throw Error("Tried to add cost to costDispensaryItem which doesn't exist!");
  }

  get moment() {
    return this.$store.state.moment;
  }

  tempCost: number | "" = "";
  //tempSince: number = Math.trunc(Date.now() / (24 * 60 * 60 * 1000)) * (24 * 60 * 60 * 1000); //midnight of today
  tempSince: number = this.moment(this.moment(Date.now()).format("YYYY-MM-DD")).valueOf();
  tempPer: number = 1.0;
  tempUnit: string = "";

  sinceDateMenu: boolean = false;
  get sinceDate() {
    return this.moment(new Date(this.tempSince)).format("YYYY-MM-DD");
  }

  set sinceDate(sinceDate: string) {
    this.tempSince = this.moment(sinceDate).valueOf();
  }

  get dispensaryItem(): Models.DispensaryItem {
    return this.$store.getters["storage"]().dispensaryItem;
  }

  history: Models.CostHistory[] = [];

  get costDispensaryItem(): Models.CostDispensaryItem {
    return this.$store.getters["storage"]().costDispensaryItem;
  }

  set costDispensaryItem(costDispensaryItem: Models.CostDispensaryItem) {
    this.$store.commit("updateUserLocationField", { path: "costDispensaryItem", value: costDispensaryItem });
  }
}
