
import { Component, Vue, Watch } from "vue-property-decorator";
import DimssaButton from "@/components/shared/dimssa-button.vue";
import lodash from "lodash";
import * as Models from "@gigalot/data-models";
import { changeTrackingComponentFragment } from "@/helpers/gql";

@Component({
  components: {
    DimssaButton,
  },
})
export default class RationCost extends Vue {
  back() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure? Unsaved work will be lost.",
      yesAction: () => {
        this.$router.push({ path: "list/ration-costs" });
      },
    });
  }

  async save() {
    let self = this;
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure that you want to save?",
      yesAction: async () => {
        try {
          this.$store.commit("popup/displayWait", "Saving...");
          if (self.costRation) {
            await self.saveCost(self.costRation);
            this.$router.push({ path: "list/ration-costs" });
          } else throw Error("no costRation");
          this.$store.commit("popup/hide");
        } catch (err) {
          console.log(err);
          this.$store.commit("popup/hide");
          this.$store.commit("popup/displayOk", { message: `Error: ${err}` });
        }
      },
    });
  }

  async saveCost(cost: Models.CostRation) {
    if (!cost) throw Error("saveCost cost is undefined");
    let costClone = lodash.cloneDeep(cost);
    costClone.metadata = this.$store.getters["user/getUpstreamMetadata"]();
    let json = await this.$store.dispatch(
      "graphQl",
      {
        gql: `mutation costRation($guid: String!, $cost: CostRationInput!) {
                costRation(guid: $guid, cost: $cost) {
                  ...changeTrackingFields
                }
              }${changeTrackingComponentFragment}`,
        variables: { guid: this.$store.state.user.location.guid, cost: costClone },
      },
      { root: true }
    );
    let costRation = this.costRation as Models.CostRation;
    costRation.changeTracking = json.data.costRation;
    //await this.$store.dispatch("data/set", { objectStore: "CostRation", items: [lodash.cloneDeep(costRation)] });
  }

  copyItem(item: any) {
    this.tempSince = item.since;
    this.tempCost = item.value;
    this.tempAutoCost = item.auto;
    // this.tempPer = item.per;
    // this.tempUnit = item.unit;
  }

  deleteItem(item: any) {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure?",
      yesAction: () => {
        if (!this.costRation) return;
        const index = this.costRation.history.indexOf(item);
        this.costRation.history.splice(index, 1);
      },
    });
  }

  async created() {
    this.$store.commit("navFuncs", { save: this.save, back: this.back });
    console.log(JSON.stringify(this.ration));
    throw Error("TODO: fix data/get");
    // let costRations: Models.CostRation[] = (await this.$store.dispatch("data/get", {
    //   objectStore: "CostRation",
    // })) as Models.CostRation[];
    // console.log(JSON.stringify(costRations));
    // this.costRation = lodash.cloneDeep(lodash.find(costRations, (cost) => cost.guid === this.ration.guid) || "");
    // if (!this.costRation) {
    //   try {
    //     console.log("Ration cost not found, adding to server");
    //     this.costRation = new Models.CostRation();
    //     this.costRation.guid = this.ration.guid;
    //     await this.saveCost(this.costRation);
    //     console.log("Ration cost added to server");
    //   } catch (err) {
    //     console.log("Error adding ration cost to server");
    //   }
    // } else {
    //   console.log("Existing ration cost found");
    // }
  }

  get ration() {
    //TODO: check if this gets set
    return this.$store.getters["storage"]().ration;
  }

  addCost() {
    if (this.costRation) {
      this.$store.commit("snackbar", { visible: false });
      if (this.tempCost !== "" || this.tempAutoCost) {
        console.log(this.tempSince);
        let historyItemIndex = lodash.findIndex(this.costRation.history, { since: this.tempSince });
        if (historyItemIndex >= 0) {
          this.costRation.history[historyItemIndex].value = this.tempAutoCost || this.tempCost === "" ? undefined : this.tempCost;
          this.costRation.history[historyItemIndex].auto = this.tempAutoCost;
          //this.costRation.history[historyItemIndex].per = this.tempPer;
        } else
          this.costRation.history.push({
            value: this.tempAutoCost || this.tempCost === "" ? undefined : this.tempCost,
            since: this.tempSince,
            auto: this.tempAutoCost,
            per: 1.0,
            unit: "tonne",
          });
      } else {
        this.$store.commit("snackbar", { message: `Invalid Cost`, timeout: 5000 });
        //this.$store.commit("popup/displayOk", `Invalid Cost`);
      }
      this.$forceUpdate();
    } else throw Error("Tried to add cost to costRation which doesn't exist!");
  }

  get moment() {
    return this.$store.state.moment;
  }

  costRation: Models.CostRation | "" = "";

  get costRations(): Models.CostRation[] {
    throw Error("fix costRation()");
    //return this.$store.getters["storage"]().costRations;
  }

  tempAutoCost: boolean = false;
  tempCost: number | "" = "";
  //tempSince: number = Math.trunc(Date.now() / (24 * 60 * 60 * 1000)) * (24 * 60 * 60 * 1000); //midnight of today
  tempSince: number = this.moment(this.moment(Date.now()).format("YYYY-MM-DD")).valueOf();

  sinceDateMenu: boolean = false;
  get sinceDate() {
    return this.moment(new Date(this.tempSince)).format("YYYY-MM-DD");
  }

  set sinceDate(sinceDate: string) {
    this.tempSince = this.moment(sinceDate).valueOf();
  }
}
