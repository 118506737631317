
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Map extends Vue {
    @Prop() private center: any;
    @Prop() private zoom: number = 6;
    @Prop() private position:any;

    get zoomLevel(){
        return this.zoom;
    }
    placeMarker(pos:any){
        this.$emit('move-marker',pos.latLng.lat(),pos.latLng.lng());
    }
    emits= ['move-marker',];
}
