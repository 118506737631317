
import { Component, Vue, Watch } from "vue-property-decorator";
import DimssaButton, { ButtonState } from "@/components/shared/dimssa-button.vue";
import * as Models from "@gigalot/data-models";
import { mapFields } from "@/store";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import lodash from "lodash";
import { changeTrackingComponentFragment } from "@/helpers/gql";
import { listCustomFeeder } from "@/helpers/graphql-list-items";
import { getItemFromServer } from "@/helpers/get-item-from-server";

@Component({
  components: {
    DimssaButton,
  },
  computed: {
    ...mapFields({
      reference: "reprocessBatch.reference",
      settled: "reprocessBatch.settled",
      type: "reprocessBatch.type",
      description: "reprocessBatch.description",
      quantityExpected: "reprocessBatch.quantityExpected",
    }),
  },
  mixins: [validationMixin],
  validations: {
    reference: { required },
  },
})
export default class ReprocessBatch extends Vue {
  changed: boolean = false;

  save() {
    if (!this.reprocessBatch.reference) {
      this.$store.commit("popup/displayOk", `Can not save, Reference required.`);
      return;
    }

    const cfGuid = this.reprocessBatch.customFeeder?.guid;
    const ncfGuid = this.reprocessBatch.newCustomFeeder?.guid;
    if (cfGuid && ncfGuid && cfGuid === ncfGuid) {
      this.$store.commit("popup/displayOk", `Can not save, Owner and New Owner must be different.`);
      return;
    }

    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure that you want to save?",
      yesAction: async () => {
        try {
          this.$store.commit("popup/displayWait", "Saving...");

          console.log(this.$store.getters["storage"]().reprocessBatch);
          let reprocessBatchClone: Models.ReprocessBatch = lodash.cloneDeep(this.$store.getters["storage"]().reprocessBatch);

          reprocessBatchClone.metadata = this.$store.getters["user/getUpstreamMetadata"]();
          let json = await this.$store.dispatch(
            "graphQl",
            {
              gql: `mutation setReprocessBatch($guid: String!, $reprocessBatch: ReprocessBatchInput!) {
                setReprocessBatch(guid: $guid, reprocessBatch: $reprocessBatch) { 
                  ...changeTrackingFields
                }
              }${changeTrackingComponentFragment}`,
              variables: { guid: this.$store.state.user.location.guid, reprocessBatch: reprocessBatchClone },
              timeout: 10 * 1000
            },
            { root: true }
          );
          console.log("graphQL: " + JSON.stringify(json));
          let reprocessBatch = this.$store.getters["storage"]().reprocessBatch as Models.ReprocessBatch;
          reprocessBatch.changeTracking = json.data.reprocessBatch;

          this.customFeederAlreadySelected = !!reprocessBatch.customFeeder;
          this.newCustomFeederAlreadySelected = !!reprocessBatch.newCustomFeeder;

          this.changed = false;
          this.$store.commit("popup/hide");
        } catch (err) {
          console.log(err);
          this.$store.commit("popup/hide");
          this.$store.commit("popup/displayOk", { message: `Error: ${err}` });
        }
      },
    });
  }

  back() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure? Unsaved work will be lost.",
      yesAction: () => {
        this.$store.commit("updateUserLocationField", { path: "reprocessBatch", value: {} });
        this.$router.push({ path: "list/reprocess-batches" });
      },
    });
  }

  @Watch("changed")
  onChangeChanged(val: any) {
    if (this.changed) this.$store.commit("navFuncs", { save: this.save, back: this.back });
    else
      this.$store.commit("navFuncs", {
        save: undefined,
        back: () => {
          this.$store.commit("updateUserLocationField", { path: "reprocessBatch", value: {} });
          this.$router.push({ path: "list/reprocess-batches" });
        },
      });
  }

  get reprocessBatch(): Models.ReprocessBatch {
    const ret: Models.ReprocessBatch = this.$store.getters["storage"]().reprocessBatch;
    return ret;
  }

  customFeederAlreadySelected: boolean = false;
  newCustomFeederAlreadySelected: boolean = false;

  async created() {
    this.$store.commit("navFuncs", {
      save: undefined,
      back: () => {
        this.$store.commit("updateUserLocationField", { path: "reprocessBatch", value: {} });
        this.$router.push({ path: "list/reprocess-batches" });
      },
    });

    if (this.customFeeder) {
      const cf = this.customFeeder;
      this.selectedCustomFeederListItem = { guid: cf.guid, text: `${cf.name}${cf.companies[0]?.name ? ` - ${cf.companies[0]?.name}` : ``}` };
      this.getSelectedCustomFeederButtonState = "success";
      this.customFeederAlreadySelected = true;
    }
    if (this.newCustomFeeder) {
      const cf = this.newCustomFeeder;
      this.selectedNewCustomFeederListItem = { guid: cf.guid, text: `${cf.name}${cf.companies[0]?.name ? ` - ${cf.companies[0]?.name}` : ``}` };
      this.getSelectedNewCustomFeederButtonState = "success";
      this.newCustomFeederAlreadySelected = true;
    }
    this.getCustomFeederListItems();
  }

  customFeederListItems: { guid: string; text: string }[] = [];
  selectedCustomFeederListItem: { guid: string; text: string } | "" = "";
  selectedNewCustomFeederListItem: { guid: string; text: string } | "" = "";
  getCustomFeederListItemsButtonState: ButtonState = "ready";
  getSelectedCustomFeederButtonState: ButtonState = "ready";
  getSelectedNewCustomFeederButtonState: ButtonState = "ready";

  async getCustomFeederListItems() {
    try {
      this.getCustomFeederListItemsButtonState = "busy";
      this.customFeederListItems = [];
      let items = await listCustomFeeder(true);
      this.customFeederListItems = items.map((i: any) => ({ guid: i.guid, text: `${i.name}${i.companyName ? ` - ${i.companyName}` : ``}` }));
      this.customFeederListItems = this.customFeederListItems.sort((p1, p2) => (p1.text > p2.text ? 1 : -1));
      this.getCustomFeederListItemsButtonState = "success";
    } catch (err) {
      console.error(err);
      this.getCustomFeederListItemsButtonState = "error";
    }

    //If selected is not found in list then add it
    if (this.customFeeder && !this.customFeederListItems.find((c) => c.guid === (this.customFeeder as Models.CustomFeeder).guid)) {
      const cf = this.customFeeder as Models.CustomFeeder;
      this.customFeederListItems.push({ guid: cf.guid, text: cf.name });
    }
    if (this.newCustomFeeder && !this.customFeederListItems.find((c) => c.guid === (this.newCustomFeeder as Models.CustomFeeder).guid)) {
      const cf = this.newCustomFeeder as Models.CustomFeeder;
      this.customFeederListItems.push({ guid: cf.guid, text: cf.name });
    }
  }

  ////////
  // customFeeder

  async getCustomFeederFromServer() {
    if (!this.selectedCustomFeederListItem || !this.selectedCustomFeederListItem.guid) {
      this.customFeeder = "";
      this.getSelectedCustomFeederButtonState = "ready";
      return;
    }
    try {
      this.getSelectedCustomFeederButtonState = "busy";
      const customFeeder = await getItemFromServer("CustomFeeder", this.selectedCustomFeederListItem.guid);
      this.customFeeder = customFeeder;
      this.getSelectedCustomFeederButtonState = "success";
    } catch (err) {
      this.getSelectedCustomFeederButtonState = "error";
      console.error(err);
      this.customFeeder = "";
    }
  }

  get modelSelectedCustomFeederListItem(): { guid: string; text: string } | "" {
    return this.selectedCustomFeederListItem;
  }

  set modelSelectedCustomFeederListItem(selectedCustomFeederListItem: { guid: string; text: string } | "") {
    if (!selectedCustomFeederListItem) selectedCustomFeederListItem = "";

    if (
      this.selectedCustomFeederListItem &&
      selectedCustomFeederListItem &&
      selectedCustomFeederListItem.guid &&
      this.selectedCustomFeederListItem.guid === selectedCustomFeederListItem.guid
    )
      return; // if same already selected then do nothing

    this.selectedCustomFeederListItem = selectedCustomFeederListItem;
    this.getCustomFeederFromServer();
  }

  get customFeeder(): Models.CustomFeeder | "" {
    return this.reprocessBatch?.customFeeder ? this.reprocessBatch.customFeeder : "";
  }

  set customFeeder(customFeeder: Models.CustomFeeder | "") {
    this.$store.commit("updateUserLocationField", { path: "reprocessBatch.customFeeder", value: customFeeder === "" ? undefined : customFeeder });
  }

  // customFeeder
  ////////
  // newCustomFeeder

  async getNewCustomFeederFromServer() {
    if (!this.selectedNewCustomFeederListItem || !this.selectedNewCustomFeederListItem.guid) {
      this.newCustomFeeder = "";
      this.getSelectedNewCustomFeederButtonState = "ready";
      return;
    }
    try {
      this.getSelectedNewCustomFeederButtonState = "busy";
      const newCustomFeeder = await getItemFromServer("CustomFeeder", this.selectedNewCustomFeederListItem.guid);
      this.newCustomFeeder = newCustomFeeder;
      this.getSelectedNewCustomFeederButtonState = "success";
    } catch (err) {
      this.getSelectedNewCustomFeederButtonState = "error";
      console.error(err);
      this.newCustomFeeder = "";
    }
  }

  get modelSelectedNewCustomFeederListItem(): { guid: string; text: string } | "" {
    return this.selectedNewCustomFeederListItem;
  }

  set modelSelectedNewCustomFeederListItem(selectedNewCustomFeederListItem: { guid: string; text: string } | "") {
    if (!selectedNewCustomFeederListItem) selectedNewCustomFeederListItem = "";

    if (
      this.selectedNewCustomFeederListItem &&
      selectedNewCustomFeederListItem &&
      selectedNewCustomFeederListItem.guid &&
      this.selectedNewCustomFeederListItem.guid === selectedNewCustomFeederListItem.guid
    )
      return; // if same already selected then do nothing

    this.selectedNewCustomFeederListItem = selectedNewCustomFeederListItem;
    this.getNewCustomFeederFromServer();
  }

  get newCustomFeeder(): Models.CustomFeeder | "" {
    return this.reprocessBatch?.newCustomFeeder ? this.reprocessBatch.newCustomFeeder : "";
  }

  set newCustomFeeder(customFeeder: Models.CustomFeeder | "") {
    this.$store.commit("updateUserLocationField", { path: "reprocessBatch.newCustomFeeder", value: customFeeder === "" ? undefined : customFeeder });
  }

  // newCustomFeeder
  ////////
}
