var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-row',[_c('v-col',[_vm._v(" Dispensary Item Cost ")])],1),_c('v-row',[_c('v-col',[_vm._v(" Code: "+_vm._s(_vm.dispensaryItem.code)+" ")])],1),_c('v-row',[_c('v-col',[_vm._v(" Description: "+_vm._s(_vm.dispensaryItem.description)+" ")])],1),_c('v-row',[_c('v-col',[_c('v-menu',{ref:"sinceDateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.sinceDate,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.sinceDate=$event},"update:return-value":function($event){_vm.sinceDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({attrs:{"label":"Effective Date","prepend-icon":"event","readonly":""},model:{value:(_vm.sinceDate),callback:function ($$v) {_vm.sinceDate=$$v},expression:"sinceDate"}},on))]}}]),model:{value:(_vm.sinceDateMenu),callback:function ($$v) {_vm.sinceDateMenu=$$v},expression:"sinceDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){return _vm.$refs.sinceDateMenu.save(_vm.sinceDate)}},model:{value:(_vm.sinceDate),callback:function ($$v) {_vm.sinceDate=$$v},expression:"sinceDate"}})],1)],1),_c('v-col',[_c('v-text-field',{staticClass:"no-spin",attrs:{"label":"Cost (R)"},model:{value:(_vm.tempCost),callback:function ($$v) {_vm.tempCost=_vm._n($$v)},expression:"tempCost"}})],1),_c('v-col',[_c('v-text-field',{staticClass:"no-spin",attrs:{"label":"Per"},model:{value:(_vm.tempPer),callback:function ($$v) {_vm.tempPer=_vm._n($$v)},expression:"tempPer"}})],1),_c('v-col',[_c('v-select',{attrs:{"label":"Units","items":[
          { text: 'cc', value: 'cc' },
          { text: 'each', value: 'each' },
        ]},model:{value:(_vm.tempUnit),callback:function ($$v) {_vm.tempUnit=$$v},expression:"tempUnit"}})],1),_c('v-col',[_c('dimssa-button',{staticClass:"button",attrs:{"buttonState":"ready"},on:{"onclick":function($event){return _vm.addCost()}}},[_vm._v("Add")])],1)],1),(_vm.costDispensaryItem)?_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"dense":"","headers":[
          { text: 'Since', value: 'since' },
          { text: 'Cost', value: 'value', sortable: false },
          { text: 'Per', value: 'per', sortable: false },
          { text: 'Unit', value: 'unit', sortable: false },
          { text: 'Actions', value: 'actions', sortable: false },
        ],"items":_vm.history,"sort-by":"since","sort-desc":""},scopedSlots:_vm._u([{key:`item.since`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.moment(item.since).format("YYYY-MM-DD"))+" ")]}},{key:`item.value`,fn:function({ item }){return [_vm._v(" R "+_vm._s(item.value))]}},{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" delete ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.copyItem(item)}}},[_vm._v(" mdi-content-copy ")])]}}],null,true)})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }